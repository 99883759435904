import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

(function setupAxiosInterceptors(axios: AxiosInstance) {
	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			if (!config?.headers?.Authorization && config?.headers) {
				const token = localStorage.getItem('jwToken');
				config.headers.Authorization = token ? `Bearer ${token}` : '';
			}

			return config;
		},
		(err: AxiosError) => Promise.reject(err)
	);
})(axiosInstance);

export default axiosInstance;
