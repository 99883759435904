import * as actionTypes from '../actions/actionTypes';

const initialState = {
	listedPages: null,
	searchedPages: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_LISTED_PAGES:
			return {
				...state,
				listedPages: action.payload,
			};
		case actionTypes.SET_SEARCHED_PAGES:
			return {
				...state,
				searchedPages: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
