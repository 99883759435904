import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { setApiKeys } from '../../../store/actions/apiKeys';
import { setUser } from '../../../store/actions/auth';

export function Logout() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setApiKeys([]));
		dispatch(setUser(null));
		localStorage.clear();
	}, [dispatch]);

	return (
		<Switch>
			<Redirect to="/auth/login" />
		</Switch>
	);
}
