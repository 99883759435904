import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import DoughnutChart from '../../../_metronic/layout/components/charts/DoughnutChart';
import { PageTitle } from '../../../_metronic/layout/core';
// import ListingPage from '../../modules/listing-page/ListingPage';

const AnalyticsContent = () => {
	const history = useHistory();

	const analyticsContent = useSelector<RootState>((state) => state.analytics.analyticsContent) as any;

	useEffect(() => {
		if (!analyticsContent || analyticsContent.length === 0) {
			history.push('/apps/pages');
		}
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<PageTitle breadcrumbs={[]}>Content Analytics</PageTitle>
			<div>
				<div onClick={() => history.goBack()} className="btn btn-md btn-primary w-40 mb-5">
					Go Back
				</div>
				<div className="row mt-5">
					{analyticsContent?.map((item: any, index: any) => (
						<div key={index} className="col-12 mb-8">
							<DoughnutChart type="exits" chartElements={item} />
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default AnalyticsContent;
