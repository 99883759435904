/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
// import { getDatabases, setListedDatabases } from '../../../store/actions/databases';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { RootState } from '../../../store/reducers';
import { getListedPages, setListedPages } from '../../../store/actions/pages';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { ActionDropdown } from '../../../_metronic/layout/components/custom/ActionDropdown';
import { getAnalyticsContent } from '../../../store/actions/analytics';
import { useHistory } from 'react-router-dom';
import { DuplicateModal } from '../../../_metronic/layout/components/custom/DuplicateModal';
import { AnalyticsModal } from '../../../_metronic/layout/components/custom/AnalyticsModal';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
	pageType: Yup.string().required('Page Type is required'),
});

const initialValues = {
	apiKey: '',
	pageType: 'site-pages',
};

const PagesListing: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [duplicateShow, setDuplicateShow] = useState(false);
	const [analyticsShow, setAnalyticsShow] = useState(false);
	const [start, setStart] = useState(
		dayjs(new Date(new Date().setFullYear(new Date().getFullYear() - 1))).format('YYYY-MM-DD')
	);
	const [end, setEnd] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
	const [loading, setLoading] = useState(true);
	const [selectedPages, setSelectedPages] = useState([]) as any;
	// const [deletePopupValue, setDeletePopupValue] = useState(null);
	const listedPages = useSelector<RootState>((state) => state.pages.listedPages) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getListedPages(values.apiKey, values?.pageType, () => setLoading(false)));
	};

	useEffect(() => {
		setLoading(true);

		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			dispatch(getListedPages(defaultApiKey, initialValues.pageType, () => setLoading(false)));
		} else {
			dispatch(setListedPages(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	const setBgColorStatus = (status: string) => {
		if (status === 'PUBLISHED') {
			return 'badge-light-success';
		} else {
			return 'badge-light-warning';
		}
	};

	const formAdditional = (formik: any) => (
		<div className="fv-row mb-10">
			<label className="form-label fs-6 fw-bolder text-dark">Page Type</label>
			<select
				disabled={apiKeys.length === 0}
				className="form-select form-select-solid"
				{...formik.getFieldProps('pageType')}
			>
				<option value="site-pages">Site Pages</option>
				<option value="landing-pages">Landing Pages</option>
			</select>
		</div>
	);

	const thead = [
		{
			width: 125,
			label: 'Name',
		},
		{
			width: 125,
			label: 'Title',
		},
		{
			width: 125,
			label: 'Author',
		},
		{
			width: 125,
			label: 'State',
		},
		{
			width: 125,
			label: 'Dates',
		},
		{
			width: 100,
			label: 'Actions',
			className: 'text-end',
		},
	];

	const selectionHtml = (apiKey: any, pageType: any) => (
		<>
			<div className="card-toolbar py-4 px-8">
				{/* begin::Dropdown */}
				<button
					style={{ background: '#eff2f5' }}
					type="button"
					className="btn btn-active-light-primary btn-sm"
					data-kt-menu-trigger="click"
					data-kt-menu-placement="bottom-start"
					data-kt-menu-flip="top-end"
				>
					Select Action
				</button>
				<ActionDropdown setAnalyticsShow={setAnalyticsShow} setDuplicateShow={setDuplicateShow} hasDuplicate={true} />
				{/* end::Dropdown */}
			</div>
			<DuplicateModal
				pageType={pageType}
				targetApi={apiKey}
				pages={selectedPages.map((page: any) => page.id)}
				show={duplicateShow}
				handleClose={() => setDuplicateShow(false)}
			/>
			<AnalyticsModal
				isValid={start && end ? true : false}
				onSubmit={() => analyticsCallback(apiKey, pageType, start, end)}
				show={analyticsShow}
				handleClose={() => setAnalyticsShow(false)}
				start={start}
				end={end}
				setStart={setStart}
				setEnd={setEnd}
			/>
		</>
	);

	const analyticsCallback = (apiKey: any, pageType: any, start: any, end: any) => {
		let pages = selectedPages.map((el: any) => el.id);
		dispatch(
			getAnalyticsContent(apiKey, start, end, pageType, pages, () => {
				history.push('/apps/content-analytics');
			})
		);
	};

	return (
		<>
			<ListingPage
				listingName="Pages"
				listingSelector={listedPages}
				listingSchema={listingSchema}
				initialValues={initialValues}
				onSubmit={onSubmit}
				loading={loading}
				thead={thead}
				formAdditional={formAdditional}
				selectionHtml={selectionHtml}
				hasCheckbox
				selectedArea={selectedPages}
				setSelectedArea={setSelectedPages}
				addNewLink="/apps/pages/create"
				findAndReplaceLink="/apps/pages/find-and-replace"
			>
				{listedPages &&
					listedPages.length > 0 &&
					listedPages.map((item: any, index: any) => (
						<tr key={index}>
							<td>
								<div className="form-check form-check-solid fv-row">
									<input
										type="checkbox"
										style={{ width: 21, height: 21 }}
										className="form-check-input"
										checked={selectedPages.find((el: any) => el === item)}
										onChange={() => {
											if (selectedPages.find((el: any) => el === item)) {
												const filtered = selectedPages.filter((el: any) => el !== item);
												setSelectedPages(filtered);
											} else {
												const newPages = [...selectedPages, item];
												setSelectedPages(newPages);
											}
										}}
									/>
								</div>
							</td>
							<td>
								<div className="d-flex align-items-center">
									<img
										src={
											item.featuredImage ? item.featuredImage : toAbsoluteUrl('/media/pages/default-placeholder.png')
										}
										alt={item?.name}
										className="page-image"
									/>
									<div className="d-flex justify-content-start flex-column">
										<a href={item?.url} rel="noreferrer noopener" target="_blank" className="text-info">
											{item?.name}
										</a>
									</div>
								</div>
							</td>
							<td>
								<div>{item?.htmlTitle}</div>
							</td>
							<td>
								<div>{item.authorName}</div>
							</td>

							<td>
								<div className={`badge ${setBgColorStatus(item?.currentState)}`}>{item?.currentState}</div>
							</td>
							<td>
								<div className="d-inline-flex flex-column">
									<div className="badge badge-light fw-normal">
										<span className="fw-bolder">Added</span>:{' '}
										{item.createdAt ? dayjs(item.createdAt).format('DD. MMM YYYY') : 'N/A'}
									</div>
									<div className="badge mt-2 badge-light fw-normal">
										<span className="fw-bolder">Update</span>:{' '}
										{item.updatedAt ? dayjs(item.updatedAt).format('DD. MMM YYYY') : 'N/A'}
									</div>
								</div>
							</td>

							<td className="text-end">
								<div
									className="btn btn-icon btn-light-danger btn-active-danger btn-sm"
									title="Delete"
									// onClick={() => setDeletePopupValue({formik })}
								>
									<KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-danger svg-icon-4" />
								</div>
							</td>
						</tr>
					))}
			</ListingPage>
		</>
	);
};

export default PagesListing;
