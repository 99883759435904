import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setAnalytics = (payload) => {
	return {
		type: actionTypes.SET_ANALYTICS,
		payload,
	};
};

export const setAnalyticsType = (payload) => {
	return {
		type: actionTypes.SET_ANALYTICS_TYPE,
		payload,
	};
};

export const getAnalytics = (apiKey, start, end, type, callback) => {
	return (dispatch) => {
		axios
			.get(`analytics?apiKey=${apiKey}&start=${start}&end=${end}&type=${type}`)
			.then(({ data }) => {
				// console.log('data?.data?.breakdowns', data?.data?.breakdowns)

				if (type === 'totals') {
					dispatch(setAnalytics(data?.data?.totals));
				} else {
					dispatch(setAnalytics(data?.data?.breakdowns));
				}

				dispatch(setAnalyticsType(type));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
};

export const setAnalyticsContent = (payload) => {
	return {
		type: actionTypes.SET_ANALYTICS_CONTENT,
		payload,
	};
};

export const getAnalyticsContent = (apiKey, start, end, type, pages, callback) => {
	return (dispatch) => {
		axios
			.get(
				`analytics/content-type?apiKey=${apiKey}&start=${start.split('-').join('')}&end=${end
					.split('-')
					.join('')}&content_type=${type}&pages=${pages.join(',')}`
			)
			.then(({ data }) => {
				// console.log('data?.data?.breakdowns', data?.data?.breakdowns)
				const result = data.data;
				let analytics = [];
				if (result.length) {
					let keys = Object.keys(result[0]);
					keys.forEach((key) => {
						let objArr = [];
						if (key !== 'breakdown' && key !== 'page') {
							result.forEach((res) => {
								if (res.page) {
									objArr = [...objArr, { name: res.page.name, value: res[key] }];
								}
							});
							analytics = [...analytics, { key, values: objArr }];
						}
					});
				}

				dispatch(setAnalyticsContent(analytics));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				// callback();
			});
	};
};
