/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../../../../_metronic/layout/components/ContentLoader';
import { getApiKeys } from '../../../../store/actions/apiKeys';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../../../store/reducers';
import { findAndReplaceWithPage } from '../../../../store/actions/pages';
import clsx from 'clsx';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
	find: Yup.string().required('Find is required'),
	replaceWith: Yup.string().required('Replace with is required'),
});

const initialValues = {
	apiKey: '',
	pageType: 'site-pages',
	find: '',
	replaceWith: ''
};

const PageFindAndReplace = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const listedDatabases = useSelector<RootState>((state) => state.databases.listedDatabases) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const [loading, setLoading] = useState(true);

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const formik = useFormik({
		initialValues,
		validationSchema: listingSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			dispatch(findAndReplaceWithPage(values, replceWithPageCallback, () => setLoading(false)));
		},
	});

	const replceWithPageCallback = () => {
		setLoading(false);
		history.push('/apps/pages/list');
	};

	useEffect(() => {
		setLoading(true);
		dispatch(getApiKeys(() => setLoading(false)));
		// eslint-disable-next-line
	}, []);

	return (
		<div className="database-listing">
			{!listedDatabases && !loading && (
				<div className="card-header border-0 px-7 py-6">
					<form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Hubspot API Key</label>
							<select
								className="form-select form-select-solid"
								{...formik.getFieldProps('apiKey')}
								disabled={apiKeys.length === 0}
							>
								<option disabled selected>
									Select key...
								</option>
								{apiKeys.map((key: any, index: any) => (
									<option key={index} value={key.apiKey}>
										{key.name}
									</option>
								))}
							</select>
							{formik.touched.apiKey && formik.errors.apiKey && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.apiKey}</span>
								</div>
							)}

							{apiKeys.length === 0 ? (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">No API keys added to account, to continue</label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Add new API Key
									</Link>
								</div>
							) : (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">Want to add new API key? </label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Click here
									</Link>
								</div>
							)}
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Page Type</label>
							<select
								disabled={apiKeys.length === 0}
								className="form-select form-select-solid"
								{...formik.getFieldProps('pageType')}
							>
								<option value="site-pages">Site Pages</option>
								<option value="landing-pages">Landing Pages</option>
							</select>
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Page</label>
							<select
								disabled={apiKeys.length === 0}
								className="form-select form-select-solid"
								// {...formik.getFieldProps('pages')}
							>
								<option value="">All Pages</option>
								{/* <option value="es">Spanish</option> */}
							</select>
						</div>


						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Find</label>
							<input
								placeholder="Enter string to find"
								{...formik.getFieldProps('find')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{ 'is-invalid': formik.touched.find && formik.errors.find },
									{
										'is-valid': formik.touched.find && !formik.errors.find,
									}
								)}
								type="text"
								autoComplete="off"
							/>
							{formik.touched.find && formik.errors.find && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.find}</span>
								</div>
							)}
						</div>


						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Replace with</label>
							<input
								placeholder="Enter string to replace with"
								{...formik.getFieldProps('replaceWith')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{ 'is-invalid': formik.touched.replaceWith && formik.errors.replaceWith },
									{
										'is-valid': formik.touched.replaceWith && !formik.errors.replaceWith,
									}
								)}
								type="text"
								autoComplete="off"
							/>
							{formik.touched.replaceWith && formik.errors.replaceWith && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.replaceWith}</span>
								</div>
							)}
						</div>

						<div className="text-center">
							<button
								type="submit"
								id="kt_sign_in_submit"
								className="btn btn-lg w-40 mb-5 btn-primary"
								disabled={!formik.isValid || apiKeys.length === 0}
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			)}

			{loading && <ContentLoader />}
		</div>
	);
};

export { PageFindAndReplace };
