export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';

export const SET_LOGS = 'SET_LOGS';
export const SET_LOGS_TOTAL = 'SET_LOGS_TOTAL';
export const SET_HOME_LOGS = 'SET_HOME_LOGS';
export const SET_LOGS_LAST_PAGE = 'SET_LOGS_LAST_PAGE';

export const SET_USER = 'SET_USER';
export const SET_LOGIN_ERR = 'SET_LOGIN_ERR';
export const SET_REGISTER_ERR = 'SET_REGISTER_ERR';

export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';
export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';

export const SET_LISTED_DOMAINS = 'SET_LISTED_DOMAINS';

export const SET_LISTED_TEMPLATES = 'SET_LISTED_TEMPLATES';

export const SET_LISTED_PAGES = 'SET_LISTED_PAGES';
export const SET_SEARCHED_PAGES = 'SET_SEARCHED_PAGES';

export const SET_LISTED_REDIRECTS = 'SET_LISTED_TEMPLATES';

export const SET_LISTED_DATABASES = 'SET_LISTED_DATABASES';

export const SET_LISTED_PORTAL_OWNERS = 'SET_LISTED_PORTAL_OWNERS';

export const SET_USERS = 'SET_USERS';
export const SET_HOME_USERS = 'SET_HOME_USERS';
export const SET_USERS_TOTAL = 'SET_USERS_TOTAL';
export const SET_USERS_LAST_PAGE = 'SET_USERS_LAST_PAGE';

export const SET_API_KEYS = 'SET_API_KEYS';

export const SET_BLOG_POSTS = 'SET_BLOG_POSTS';
export const SET_BLOG_TAGS = 'SET_BLOG_TAGS';
export const SET_BLOG_AUTHORS = 'SET_BLOG_AUTHORS';

export const SET_FILES = 'SET_FILES';

export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_ANALYTICS_TYPE = 'SET_ANALYTICS_TYPE';

export const SET_ANALYTICS_CONTENT = 'SET_ANALYTICS_CONTENT';
