import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification } from './notification';

export const setListedDatabases = (payload) => {
	return {
		type: actionTypes.SET_LISTED_DATABASES,
		payload,
	};
};

export const getDatabases = (apiKey, callback) => {
	return (dispatch) => {
		axios
			.get(`database?apiKey=${apiKey}`)
			.then(({ data }) => {
				dispatch(setListedDatabases(data.data));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
};