import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { getAnalytics, setAnalytics } from '../../../store/actions/analytics';
import BarChart2 from '../../../_metronic/layout/components/charts/Bartchart2';
import { camelToRegular } from '../../../_metronic/assets/ts/_utils/_TypesHelpers';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
	start: Yup.string().required('Start is required'),
	end: Yup.string().required('End is required'),
});

const initialValues = {
	apiKey: '',
	start: dayjs(new Date(new Date().setFullYear(new Date().getFullYear() - 1))).format('YYYY-MM-DD'),
	end: dayjs(new Date()).format('YYYY-MM-DD'),
	type: 'totals',
};

const minsSecs = (seconds: any) => {
	let totalSeconds = seconds;
	// eslint-disable-next-line
	const hours = Math.floor(totalSeconds / 3600);
	totalSeconds %= 3600;
	const minutes = Math.floor(totalSeconds / 60);
	const finalSecs = totalSeconds % 60;

	return `${minutes ? minutes + 'min ' : ''}${finalSecs + 's'}`;
};

const Analytics = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;
	const listedAnalytics = useSelector<RootState>((state) => state.analytics.listedAnalytics) as any;
	const analyticsType = useSelector<RootState>((state) => state.analytics.analyticsType) as any;

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(
			getAnalytics(values.apiKey, values.start.replaceAll('-', ''), values.end.replaceAll('-', ''), values.type, () =>
				setLoading(false)
			)
		);
	};

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	useEffect(() => {
		setLoading(true);
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			setLoading(true);
			dispatch(
				getAnalytics(
					defaultApiKey,
					initialValues.start.replaceAll('-', ''),
					initialValues.end.replaceAll('-', ''),
					initialValues.type,
					() => setLoading(false)
				)
			);
		} else {
			dispatch(setAnalytics(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	const thead = [
		{
			width: 100,
			label: 'Country',
			className: 'fs-7',
		},
		{
			width: 100,
			label: 'Sessions',
			className: 'fs-7',
		},
		{
			width: 100,
			label: '% New session',
			className: 'fs-7',
		},
		{
			width: 100,
			label: 'Session contact rate',
			className: 'fs-7',
		},
		{
			width: 100,
			label: 'Contact to customer rate',
			className: 'fs-7',
		},
		{
			width: 100,
			label: 'Customers',
			className: 'fs-7',
		},
		{
			width: 100,
			label: 'Bounce rate',
			className: 'fs-7',
		},
		{
			width: 150,
			label: 'Session length',
			className: 'fs-7',
		},
	];

	const formAdditional = (formik: any) => (
		<div className="fv-row mb-10">
			<div className="row">
				<div className="col-sm-6">
					<label className="form-label fs-6 fw-bolder text-dark">Start</label>
					<input
						type="date"
						className="form-control form-control-lg form-control-solid mb-4"
						{...formik.getFieldProps('start')}
					/>
				</div>
				<div className="col-sm-6">
					<label className="form-label fs-6 fw-bolder text-dark">End</label>
					<input
						type="date"
						className="form-control form-control-lg form-control-solid mb-4"
						{...formik.getFieldProps('end')}
					/>
				</div>
			</div>

			<label className="form-label fs-6 fw-bolder text-dark">Type</label>
			<select
				disabled={apiKeys.length === 0}
				className="form-select form-select-solid"
				{...formik.getFieldProps('type')}
			>
				<option value="totals">Totals</option>
				<option value="sessions">Sessions</option>
				<option value="sources">Sources</option>
				<option value="geolocation">Geolocation</option>
			</select>
		</div>
	);

	return (
		<>
			<ListingPage
				listingName="Analytics"
				listingSelector={listedAnalytics}
				listingSchema={listingSchema}
				initialValues={initialValues}
				onSubmit={onSubmit}
				loading={loading}
				thead={thead}
				formAdditional={formAdditional}
				hideTable={analyticsType !== 'geolocation'}
				hideTotal={analyticsType !== 'geolocation'}
			>
				{analyticsType === 'geolocation' &&
					listedAnalytics?.length &&
					listedAnalytics.map((item: any, index: any) => (
						<tr key={index}>
							<td className="text-uppercase">{item?.breakdown}</td>
							<td>{item?.rawViews}</td>
							<td>{item?.newVisitorSessionRate ? (item?.newVisitorSessionRate * 100).toFixed(2) + '%' : 'N/A'}</td>
							<td>{item?.sessionToContactRate ? (item?.sessionToContactRate * 100).toFixed(2) + '%' : 'N/A'}</td>
							<td>{item?.contactToCustomerRate ? (item?.contactToCustomerRate * 100).toFixed(2) + '%' : 'N/A'}</td>
							<td>{item?.customers}</td>
							<td>{item?.bounceRate ? (item?.bounceRate * 100).toFixed(2) + '%' : 'N/A'}</td>
							<td>{minsSecs(parseInt(item?.timePerSession))}</td>
						</tr>
					))}
				{analyticsType === 'sessions' && listedAnalytics?.length && (
					<div className="row mt-5">
						{listedAnalytics.map((item: any, index: any) => (
							<div key={index} className="col-md-6 col-12 mb-8">
								<BarChart2 chartElements={item} />
							</div>
						))}
					</div>
				)}
				{analyticsType === 'sources' && listedAnalytics?.length && (
					<div className="row mt-5">
						{listedAnalytics.map((singleAnalytics: any) => (
							<div className="col-6 mb-5">
								<div className="card listedDomains mt-5`">
									<div className="card-header">
										<h3 className="card-title align-items-start flex-column">
											<span className="card-label fw-bolder fs-3 mb-1">
												{camelToRegular(singleAnalytics.breakdown)}
											</span>
										</h3>
									</div>
									<div className="card-body p-3">
										{Object.entries(singleAnalytics).map((item: any) => {
											if (isNaN(item[1])) return null;
											return (
												<div className="p-3 border-bottom d-flex justify-content-between">
													<span className="text-muted">{camelToRegular(item[0])} :</span>
													<span className="ml-auto fw-bolder">{item[1] + ''}</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						))}
					</div>
				)}
				{analyticsType === 'totals' && listedAnalytics && (
					<div className="row mt-5">
						<div className="col-12">
							<div className="card listedDomains mt-5`">
								<div className="card-header">
									<h3 className="card-title align-items-start flex-column">
										<span className="card-label fw-bolder fs-3 mb-1">Totals</span>
									</h3>
								</div>
								<div className="card-body p-3">
									{Object.entries(listedAnalytics).map((item: any) => {
										if (isNaN(item[1])) return null;
										return (
											<div className="p-3 border-bottom d-flex justify-content-between">
												<span className="text-muted">{camelToRegular(item[0])} :</span>
												<span className="ml-auto fw-bolder">{item[1] + ''}</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				)}
			</ListingPage>
		</>
	);
};

export default Analytics;
