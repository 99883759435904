/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

type Props = {
	hasDuplicate: boolean;
	setDuplicateShow?: any;
	setAnalyticsShow?: any;
};

const ActionDropdown: FC<Props> = ({ hasDuplicate, setDuplicateShow, setAnalyticsShow }) => {
	return (
		<>
			<div
				className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px"
				data-kt-menu="true"
			>
				<div className="separator mb-3 opacity-75"></div>

				{hasDuplicate && (
					<div className="menu-item px-3">
						<a href="#" className="menu-link px-3" onClick={() => setDuplicateShow(true)}>
							Duplicate
						</a>
					</div>
				)}

				<div className="menu-item px-3">
					<a href="#" className="menu-link px-3" onClick={() => setAnalyticsShow(true)}>
						Analytics
					</a>
				</div>

				<div className="separator mt-3 opacity-75"></div>
			</div>
		</>
	);
};

export { ActionDropdown };
