import { PageTitle } from '../../../_metronic/layout/core';

export default function Pricing() {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Pricing</PageTitle>
			<div className="card" id="kt_pricing">
				{/* <!--begin::Card body--> */}
				<div className="card-body p-lg-17">
					{/* <!--begin::Plans--> */}
					<div className="d-flex flex-column">
						{/* <!--begin::Heading--> */}
						<div className="mb-13 text-center">
							<h1 className="fs-2hx fw-bolder mb-5">Choose Your Plan</h1>
							<div className="text-gray-400 fw-bold fs-5">
								If you need more info about our pricing, please check{' '}
								<span className="link-primary fw-bolder">Pricing Guidelines</span>.
							</div>
						</div>
						{/* <!--end::Heading--> */}
						{/* <!--begin::Nav group--> */}
						<div className="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true">
							<button
								className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active"
								data-kt-plan="month"
							>
								Monthly
							</button>
							<button
								className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"
								data-kt-plan="annual"
							>
								Annual
							</button>
						</div>
						{/* <!--end::Nav group--> */}
						{/* <!--begin::Row--> */}
						<div className="row g-10">
							{/* <!--begin::Col--> */}
							<div className="col-xl-4">
								<div className="d-flex h-100 align-items-center">
									{/* <!--begin::Option--> */}
									<div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
										{/* <!--begin::Heading--> */}
										<div className="mb-7 text-center">
											{/* <!--begin::Title--> */}
											<h1 className="text-dark mb-5 fw-boldest">Startup</h1>
											{/* <!--end::Title--> */}
											{/* <!--begin::Description--> */}
											<div className="text-gray-400 fw-bold mb-5">
												Optimal for 10+ team size
												<br />
												and new startup
											</div>
											{/* <!--end::Description--> */}
											{/* <!--begin::Price--> */}
											<div className="text-center">
												<span className="mb-2 text-primary">$</span>
												<span
													className="fs-3x fw-bolder text-primary"
													data-kt-plan-price-month="39"
													data-kt-plan-price-annual="399"
												>
													39
												</span>
												<span className="fs-7 fw-bold opacity-50">
													/<span data-kt-element="period">Mon</span>
												</span>
											</div>
											{/* <!--end::Price--> */}
										</div>
										{/* <!--end::Heading--> */}
										{/* <!--begin::Features--> */}
										<div className="w-100 mb-10">
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Up to 10 Active Users</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
													Up to 30 Project Integrations
												</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Analytics Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-400 flex-grow-1">Finance Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg--> */}
												<span className="svg-icon svg-icon-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect
															x="7"
															y="15.3137"
															width="12"
															height="2"
															rx="1"
															transform="rotate(-45 7 15.3137)"
															fill="black"
														></rect>
														<rect
															x="8.41422"
															y="7"
															width="12"
															height="2"
															rx="1"
															transform="rotate(45 8.41422 7)"
															fill="black"
														></rect>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-400 flex-grow-1">Accounting Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg--> */}
												<span className="svg-icon svg-icon-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect
															x="7"
															y="15.3137"
															width="12"
															height="2"
															rx="1"
															transform="rotate(-45 7 15.3137)"
															fill="black"
														></rect>
														<rect
															x="8.41422"
															y="7"
															width="12"
															height="2"
															rx="1"
															transform="rotate(45 8.41422 7)"
															fill="black"
														></rect>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-400 flex-grow-1">Network Platform</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg--> */}
												<span className="svg-icon svg-icon-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect
															x="7"
															y="15.3137"
															width="12"
															height="2"
															rx="1"
															transform="rotate(-45 7 15.3137)"
															fill="black"
														></rect>
														<rect
															x="8.41422"
															y="7"
															width="12"
															height="2"
															rx="1"
															transform="rotate(45 8.41422 7)"
															fill="black"
														></rect>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center">
												<span className="fw-bold fs-6 text-gray-400 flex-grow-1">Unlimited Cloud Space</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg--> */}
												<span className="svg-icon svg-icon-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect
															x="7"
															y="15.3137"
															width="12"
															height="2"
															rx="1"
															transform="rotate(-45 7 15.3137)"
															fill="black"
														></rect>
														<rect
															x="8.41422"
															y="7"
															width="12"
															height="2"
															rx="1"
															transform="rotate(45 8.41422 7)"
															fill="black"
														></rect>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
										</div>
										{/* <!--end::Features--> */}
										{/* <!--begin::Select--> */}
										<span className="btn btn-sm btn-primary">Select</span>
										{/* <!--end::Select--> */}
									</div>
									{/* <!--end::Option--> */}
								</div>
							</div>
							{/* <!--end::Col--> */}
							{/* <!--begin::Col--> */}
							<div className="col-xl-4">
								<div className="d-flex h-100 align-items-center">
									{/* <!--begin::Option--> */}
									<div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-20 px-10">
										{/* <!--begin::Heading--> */}
										<div className="mb-7 text-center">
											{/* <!--begin::Title--> */}
											<h1 className="text-dark mb-5 fw-boldest">Advanced</h1>
											{/* <!--end::Title--> */}
											{/* <!--begin::Description--> */}
											<div className="text-gray-400 fw-bold mb-5">
												Optimal for 100+ team siz
												<br />e and grown company
											</div>
											{/* <!--end::Description--> */}
											{/* <!--begin::Price--> */}
											<div className="text-center">
												<span className="mb-2 text-primary">$</span>
												<span
													className="fs-3x fw-bolder text-primary"
													data-kt-plan-price-month="339"
													data-kt-plan-price-annual="3399"
												>
													339
												</span>
												<span className="fs-7 fw-bold opacity-50">
													/<span data-kt-element="period">Mon</span>
												</span>
											</div>
											{/* <!--end::Price--> */}
										</div>
										{/* <!--end::Heading--> */}
										{/* <!--begin::Features--> */}
										<div className="w-100 mb-10">
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Up to 10 Active Users</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
													Up to 30 Project Integrations
												</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Analytics Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Finance Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Accounting Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-400 flex-grow-1">Network Platform</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg--> */}
												<span className="svg-icon svg-icon-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect
															x="7"
															y="15.3137"
															width="12"
															height="2"
															rx="1"
															transform="rotate(-45 7 15.3137)"
															fill="black"
														></rect>
														<rect
															x="8.41422"
															y="7"
															width="12"
															height="2"
															rx="1"
															transform="rotate(45 8.41422 7)"
															fill="black"
														></rect>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center">
												<span className="fw-bold fs-6 text-gray-400 flex-grow-1">Unlimited Cloud Space</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg--> */}
												<span className="svg-icon svg-icon-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<rect
															x="7"
															y="15.3137"
															width="12"
															height="2"
															rx="1"
															transform="rotate(-45 7 15.3137)"
															fill="black"
														></rect>
														<rect
															x="8.41422"
															y="7"
															width="12"
															height="2"
															rx="1"
															transform="rotate(45 8.41422 7)"
															fill="black"
														></rect>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
										</div>
										{/* <!--end::Features--> */}
										{/* <!--begin::Select--> */}
										<span className="btn btn-sm btn-primary">Select</span>
										{/* <!--end::Select--> */}
									</div>
									{/* <!--end::Option--> */}
								</div>
							</div>
							{/* <!--end::Col--> */}
							{/* <!--begin::Col--> */}
							<div className="col-xl-4">
								<div className="d-flex h-100 align-items-center">
									{/* <!--begin::Option--> */}
									<div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
										{/* <!--begin::Heading--> */}
										<div className="mb-7 text-center">
											{/* <!--begin::Title--> */}
											<h1 className="text-dark mb-5 fw-boldest">Enterprise</h1>
											{/* <!--end::Title--> */}
											{/* <!--begin::Description--> */}
											<div className="text-gray-400 fw-bold mb-5">
												Optimal for 1000+ team
												<br />
												and enterpise
											</div>
											{/* <!--end::Description--> */}
											{/* <!--begin::Price--> */}
											<div className="text-center">
												<span className="mb-2 text-primary">$</span>
												<span
													className="fs-3x fw-bolder text-primary"
													data-kt-plan-price-month="999"
													data-kt-plan-price-annual="9999"
												>
													999
												</span>
												<span className="fs-7 fw-bold opacity-50">
													/<span data-kt-element="period">Mon</span>
												</span>
											</div>
											{/* <!--end::Price--> */}
										</div>
										{/* <!--end::Heading--> */}
										{/* <!--begin::Features--> */}
										<div className="w-100 mb-10">
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Up to 10 Active Users</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
													Up to 30 Project Integrations
												</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Analytics Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Finance Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Accounting Module</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center mb-5">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Network Platform</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
											{/* <!--begin::Item--> */}
											<div className="d-flex align-items-center">
												<span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Unlimited Cloud Space</span>
												{/* <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg--> */}
												<span className="svg-icon svg-icon-1 svg-icon-success">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
														<path
															d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
															fill="black"
														></path>
													</svg>
												</span>
												{/* <!--end::Svg Icon--> */}
											</div>
											{/* <!--end::Item--> */}
										</div>
										{/* <!--end::Features--> */}
										{/* <!--begin::Select--> */}
										<span className="btn btn-sm btn-primary">Select</span>
										{/* <!--end::Select--> */}
									</div>
									{/* <!--end::Option--> */}
								</div>
							</div>
							{/* <!--end::Col--> */}
						</div>
						{/* <!--end::Row--> */}
					</div>
					{/* <!--end::Plans--> */}
				</div>
				{/* <!--end::Card body--> */}
			</div>
		</>
	);
}
