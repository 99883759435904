import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, LinearScale, TimeScale, Tooltip, Legend, CategoryScale, DoughnutController, PieController, ArcElement } from 'chart.js';
import 'chartjs-adapter-moment';
// import { isMobile } from 'react-device-detect';
import { camelToRegular } from '../../../assets/ts/_utils/_TypesHelpers';

Chart.register(DoughnutController, ArcElement, PieController, LinearScale, TimeScale, CategoryScale, Tooltip, Legend);

function random_rgba() {
	var o = Math.round,
		r = Math.random,
		s = 255;
	return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 0.7 + ')';
}

// function capitalize(str) {
// 	const lower = str.toLowerCase();
// 	return str.charAt(0).toUpperCase() + lower.slice(1);
// }

const DoughnutChart = ({ chartElements, type }) => {
	const colorsArray = [
		'rgba(255, 99, 132, 0.7)',
		'rgba(54, 162, 235, 0.7)',
		'rgba(255, 206, 86, 0.7)',
		'rgba(75, 192, 192, 0.7)',
		'rgba(48, 99, 142, 0.7)',
		'rgba(32, 130, 52, 0.7)',
		'rgba(58, 42, 32, 0.7)',
		'rgba(0, 0, 0, 0.8)',
		'rgba(179, 27, 227, 0.7)',
		'rgba(116, 210, 1, 0.7)',
		'rgba(20, 134, 40, 0.7)',
		'rgba(152, 127, 207, 0.7)',
		'rgba(91, 47, 31, 0.9)',
		'rgba(141, 225, 182, 0.7)',
		'rgba(139, 26, 80, 0.7)',
		'rgba(22, 225, 200, 0.7)',
		'rgba(254, 193, 166, 0.7)',
		'rgba(169, 153, 185, 0.7)',
		'rgba(84, 144, 73, 0.7)',
	];

	console.log(chartElements);

	const pieOptions = {
		plugins:{
			legend: {
				display: false,
			},
			elements: {
				arc: {
					borderWidth: 0
				}
			}
		},
		
	};

	console.log(chartElements)
	const data = {
		maintainAspectRatio: false,
		responsive: true,
		labels: chartElements?.values.map((item) => (item.name)),
		// datasets: chartElements?.values.map((item, index) => ({
		// 	data: item.value,
		// 	// label: item.name,
		// 	backgroundColor: colorsArray[index] ? colorsArray[index] : random_rgba(),
		// 	hoverBackgroundColor: colorsArray[index] ? colorsArray[index] : random_rgba(),
		// })),
		datasets: [
			{
				data: chartElements?.values.map((item) => item.value),
				backgroundColor: chartElements?.values.map((item,index) => colorsArray[index] ? colorsArray[index] : random_rgba()),
				// hoverBackgroundColor: random_rgba(),
			}
		]
	};

	console.log('labels', chartElements?.values.map((item) => (item.name)))
	

	// eslint-disable-next-line
	const barChart = (
		<div className="card listedDomains mt-5`">
			<div className="card-header">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bolder fs-3 mb-1" style={{textTransform: 'capitalize'}}>
						{camelToRegular(chartElements.key)}
					</span>
				</h3>
			</div>
			<div className="card-body p-3">
				<Bar
					data={data}
					options={pieOptions}
					// ref={input => {
					//   chartInstance = input;
					// }}
        />
			</div>
		</div>
	);
	return barChart;
};

export default DoughnutChart;
