import * as actionTypes from '../actions/actionTypes';

const initialState = {
	files: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_FILES:
			return {
				...state,
				files: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
