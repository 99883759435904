import * as actionTypes from '../actions/actionTypes';

const initialState = {
	logs: [],
	logsTotal: 0,
	homeLogs: [],
	logsLastPage: 1
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_LOGS:
			return {
				...state,
				logs: action.payload,
			};
		case actionTypes.SET_LOGS_TOTAL:
			return {
				...state,
				logsTotal: action.payload,
			};
		case actionTypes.SET_HOME_LOGS:
			return {
				...state,
				homeLogs: action.payload,
			};
		case actionTypes.SET_LOGS_LAST_PAGE:
			return {
				...state,
				logsLastPage: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
