import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

type Props = {
	color: any;
	name: string;
	createdAt: any;
};

const SingleUser: React.FC<Props> = ({ color, name, createdAt }) => {
	return (
		<div className="d-flex align-items-center mb-7">
			<div className="symbol symbol-50px me-5">
				<span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>{name.charAt(0)}</span>
			</div>
			<div className="flex-grow-1">
				<div className="text-dark fw-bolder fs-6">{name}</div>
				<span className="text-muted d-block fw-bold">{dayjs(createdAt).fromNow()}</span>
			</div>
		</div>
	);
};

export default SingleUser;
