/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { RootState } from '../../../../store/reducers';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';

export function AsideMenuMain() {
	const intl = useIntl();
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.account, shallowEqual) as UserModel;

	return (
		<>
			<AsideMenuItem
				to="/dashboard"
				icon="/media/icons/duotune/art/art002.svg"
				title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
				fontIcon="bi-app-indicator"
			/>
			<AsideMenuItem to="/pricing" title="Pricing" icon="/media/icons/duotune/ecommerce/ecm003.svg" />

			<div className="menu-item">
				<div className="menu-content pt-4 pb-0">
					<span className="menu-section text-muted text-uppercase fs-9 ls-1">CMS Apps</span>
				</div>
			</div>

			<AsideMenuItem to="/apps/domains" title="Domains" icon="/media/icons/duotune/maps/map004.svg" />

			<AsideMenuItem to="/apps/templates" title="Templates" icon="/media/icons/duotune/layouts/lay009.svg" />

			<AsideMenuItem to="/apps/pages" title="Pages" icon="/media/icons/duotune/general/gen005.svg" />

			<AsideMenuItemWithSub to="/apps/blogs" title="Blogs" icon="/media/icons/duotune/art/art008.svg">
				<AsideMenuItem to="/apps/blogs/posts" title="Posts" hasBullet={true} />
				<AsideMenuItem to="/apps/blogs/tags" title="Tags" hasBullet={true} />
				<AsideMenuItem to="/apps/blogs/authors" title="Authors" hasBullet={true} />
			</AsideMenuItemWithSub>

			<AsideMenuItem to="/apps/search" title="Search" icon="/media/icons/duotune/general/gen004.svg" />
			{/* <AsideMenuItem to="/apps/search/content" title="Content Search" hasBullet={true} />
				<AsideMenuItem to="/apps/search/indexed-data" title="Indexed Data Search" hasBullet={true} />
			</AsideMenuItemWithSub> */}

			<AsideMenuItem to="/apps/analytics" title="Analytics" icon="/media/icons/duotune/graphs/gra001.svg" />

			<AsideMenuItemWithSub to="/apps/performance" title="Performance" icon="/media/icons/duotune/general/gen002.svg">
				<AsideMenuItem to="/apps/performance/website-performance" title="Website Performance" hasBullet={true} />
				<AsideMenuItem to="/apps/performance/website-uptime" title="Website Uptime" hasBullet={true} />
			</AsideMenuItemWithSub>

			<AsideMenuItem to="/apps/databases" title="HubDB" icon="/media/icons/duotune/finance/fin005.svg" />

			<AsideMenuItem to="/apps/redirects" title="Redirects" icon="/media/icons/duotune/maps/map009.svg" />

			<div className="menu-item">
				<div className="menu-content pt-4 pb-0">
					<span className="menu-section text-muted text-uppercase fs-9 ls-1">CRM Apps</span>
				</div>
			</div>

			<AsideMenuItem to="/apps/owners" title="Portal Owners" icon="/media/icons/duotune/general/gen049.svg" />

			<div className="menu-item">
				<div className="menu-content pt-4 pb-0">
					<span className="menu-section text-muted text-uppercase fs-9 ls-1">Storage</span>
				</div>
			</div>

			<AsideMenuItem to="/apps/files" title="Files" icon="/media/icons/duotune/files/fil012.svg" />

			{user?.type === 'admin' && (
				<>
					<div className="menu-item">
						<div className="menu-content pt-4 pb-0">
							<span className="menu-section text-muted text-uppercase fs-9 ls-1">Admin</span>
						</div>
					</div>

					<AsideMenuItem
						to="/admin/users"
						title={intl.formatMessage({ id: 'MENU.USERS' })}
						icon="/media/icons/duotune/communication/com005.svg"
					/>
				</>
			)}

			<div className="menu-item">
				<div className="menu-content pt-4 pb-0">
					<span className="menu-section text-muted text-uppercase fs-9 ls-1">Setup</span>
				</div>
			</div>

			<AsideMenuItemWithSub
				to="/setup/account"
				title={intl.formatMessage({ id: 'MENU.ACCOUNT' })}
				icon="/media/icons/duotune/communication/com006.svg"
				fontIcon="bi-person"
			>
				<AsideMenuItem
					to="/setup/account/overview"
					title={intl.formatMessage({ id: 'MENU.OVERVIEW' })}
					hasBullet={true}
				/>
				<AsideMenuItem
					to="/setup/account/settings"
					title={intl.formatMessage({ id: 'MENU.SETTINGS' })}
					hasBullet={true}
				/>
				<AsideMenuItem to="/setup/account/activity" hasBullet={true} title="Activity" />
			</AsideMenuItemWithSub>

			<AsideMenuItem
				to="/api-keys"
				title="API Keys"
				fontIcon="bi-person"
				icon="/media/icons/duotune/coding/iconKey.svg"
			/>

			<div className="menu-item">
				<div className="menu-content">
					<div className="separator mx-1 my-4"></div>
				</div>
			</div>
		</>
	);
}
