/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersFilter } from '../../../../store/actions/users';
import { RootState } from '../../../../store/reducers';
import ContentLoader from '../../../../_metronic/layout/components/ContentLoader';
import SingleUser from './SingleUser';

type Props = {
	className: string;
};

const colors = new Map<string, string>([
	["basic", "success"],
	["admin", "danger"]
])

const DashboardUsers: React.FC<Props> = ({ className }) => {
	const dispatch = useDispatch();
	const homeUsers = useSelector<RootState>((state) => state.users.homeUsers) as any;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		dispatch(
			getUsersFilter(
				{ pageSize: 4, pageNumber: 1, sortField: 'createdAt', sortOrder: 'desc' },
				() => setLoading(false),
				true
			)
		);
		// eslint-disable-next-line
	}, []);

	return (
		<div className={`card ${className}`} style={{ minHeight: 300 }}>
			{/* begin::Header */}
			<div className="card-header border-0">
				<h3 className="card-title fw-bolder text-dark">Latest Users</h3>
			</div>
			{/* end::Header */}
			{/* begin::Body */}
			<div className="card-body pt-2">
				{/* begin::Item */}
				{loading && <ContentLoader />}
				{homeUsers.length > 0 &&
					!loading &&
					homeUsers.map((user: any, index: any) => (
						<SingleUser
							key={index}
							color={user.type && colors.get(user.type)}
							name={!user.firstName && !user.lastName ? user.email : `${user.firstName} ${user.lastName}`}
							createdAt={user.createdAt}
						/>
					))}

				{/* end::Item */}
			</div>
			{/* end::Body */}
		</div>
	);
};

export { DashboardUsers };
