/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { getApiKeys } from '../../../../store/actions/apiKeys';
import { duplicatePages } from '../../../../store/actions/pages';

type Props = {
	show: boolean;
	handleClose: () => void;
	targetApi: string;
	pageType: string;
	pages: any;
};

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const DuplicateModal: React.FC<Props> = ({ show, handleClose, targetApi, pageType, pages }) => {
	const dispatch = useDispatch();
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;
	const defaultApiKey = apiKeys?.length <= 1 ? '' : apiKeys.find((key: any) => key.apiKey !== targetApi)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: listingSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			dispatch(
				duplicatePages(
					{ apiKey: targetApi, pageType, apiKeyTo: values.apiKey, pages },
					() => {
						setLoading(false);
						handleClose();
					},
					() => setLoading(false)
				)
			);
		},
	});

	useEffect(() => {
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Modal
			id="kt_modal_create_app"
			tabIndex={-1}
			aria-hidden="true"
			dialogClassName="modal-dialog-centered mw-1000px h-auto"
			show={show}
			onHide={handleClose}
		>
			<div className="container-xxl px-5 py-5">
				<div className="modal-header py-2 d-flex justify-content-between border-0">
					{/* begin::Close */}
					<h3>Duplicate selected pages</h3>
					<div className="btn btn-icon btn-sm btn-light-primary" onClick={handleClose}>
						<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
					</div>
					{/* end::Close */}
				</div>
				<div className="separator mx-1 mt-4 mb-0"></div>

				<div className="modal-body">
					<form onSubmit={formik.handleSubmit} noValidate>
						<div>
							<label className="form-label fs-6 fw-bolder text-dark">Targeted Hubspot API Key</label>
							<select
								className="form-select form-select-solid"
								{...formik.getFieldProps('apiKey')}
								disabled={apiKeys.length === 0}
							>
								<option disabled selected>
									Select key...
								</option>
								{apiKeys?.length &&
									apiKeys?.map(
										(key: any, index: any) =>
											key.apiKey !== targetApi && (
												<option key={index} value={key.apiKey}>
													{key.name}
												</option>
											)
									)}
							</select>
							{formik.touched.apiKey && formik.errors.apiKey && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.apiKey}</span>
								</div>
							)}

							{apiKeys.length === 0 ? (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">No API keys added to account, to continue</label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Add new API Key
									</Link>
								</div>
							) : (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">Want to add new API key? </label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Click here
									</Link>
								</div>
							)}
						</div>
						<div className="text-center">
							<button
								type="submit"
								id="kt_sign_in_submit"
								className="btn btn-md w-40 mb-3 btn-primary"
								disabled={!formik.isValid || apiKeys.length <= 1}
							>
								{!loading && 'Submit'}
								{loading && (
									<span className="indicator-progress" style={{ display: 'block' }}>
										Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export { DuplicateModal };
