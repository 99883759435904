import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import ApiKeysWrapper from '../pages/apiKeys/ApiKeysWrapper';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import DomainsListing from '../pages/domains/DomainsListing';
import DatabasesListing from '../pages/databases/DatabasesListing';
import PagesListing from '../pages/pages-listing/PagesListing';
import SearchContent from '../pages/search-content/SearchContent';
import TemplatesListing from '../pages/templates/TemplatesListing';
import RedirectsListing from '../pages/redirects/RedirectsListing';
import PortalOwnersListing from '../pages/portal-owners/PortalOwnersListing';
import PageCreateWrapper from '../pages/pages-create/PageCreateWrapper';
import BlogPosts from '../pages/blog/posts/BlogPosts';
import BlogTags from '../pages/blog/tags/BlogTags';
import BlogAuthors from '../pages/blog/authors/BlogAuthors';
import { UserModel } from '../modules/auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import FilesWrapper from '../pages/documents/FilesWrapper';
import Analytics from '../pages/analytics/Analytics';
import Pricing from '../pages/pricing/Pricing';
import AnalyticsContent from '../pages/analytics/AnalyticsContent';
import PageFindAndReplaceWrapper from '../pages/pages-find-and-replace/PageFindAndReplaceWrapper';
import BlogsFindAndReplaceWrapper from '../pages/blog-find-and-replace/BlogFindAndReplaceWrapper';

export function PrivateRoutes() {
	// const LogsPage = import('../pages/logs/LogsWrapper');
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
	const UsersPage = lazy(() => import('../modules/admin/users/UsersPage'));

	const user: UserModel = useSelector<RootState>(({ auth }) => auth.account, shallowEqual) as UserModel;

	return (
		<Suspense fallback={<FallbackView />}>
			<Switch>
				<Route path="/dashboard" component={DashboardWrapper} />

				{/* APPS */}

				{/* -- PRICING -- */}
				<Route path="/pricing" component={Pricing} />

				{/* -- DOMAINS -- */}
				<Route path="/apps/domains" component={DomainsListing} />

				{/* -- TEMPLATES -- */}
				<Route path="/apps/templates" component={TemplatesListing} />

				{/* -- PAGES -- */}
				<Route path="/apps/pages/create" component={PageCreateWrapper} />
				<Route path="/apps/pages/find-and-replace" component={PageFindAndReplaceWrapper} />
				<Route path="/apps/pages" component={PagesListing} />

				{/* -- BLOGS -- */}
				<Route path="/apps/blogs/posts/find-and-replace" component={BlogsFindAndReplaceWrapper} />
				<Route path="/apps/blogs/posts" component={BlogPosts} />
				<Route path="/apps/blogs/tags" component={BlogTags} />
				<Route path="/apps/blogs/authors" component={BlogAuthors} />

				{/* -- SEARCH -- */}
				<Route path="/apps/search" component={SearchContent} />

				{/* -- DATABASE -- */}
				<Route path="/apps/databases" component={DatabasesListing} />

				{/* -- REDIRECTS -- */}
				<Route path="/apps/redirects" component={RedirectsListing} />

				{/* -- PORTAL OWNERS -- */}
				<Route path="/apps/owners" component={PortalOwnersListing} />

				{/* -- FILES -- */}
				<Route path="/apps/files" component={FilesWrapper} />

				{/* -- ANALYTICS -- */}
				<Route path="/apps/analytics" component={Analytics} />
				<Route path="/apps/content-analytics" component={AnalyticsContent} />

				{/* ADMIN */}
				{user?.type === 'admin' && <Route path="/admin/users" component={UsersPage} />}

				{/* SETUP */}
				<Route path="/setup/account" component={AccountPage} />
				<Route path="/api-keys" component={ApiKeysWrapper} />

				<Redirect from="/auth" to="/dashboard" />
				<Redirect exact from="/" to="/dashboard" />
				<Redirect to="error/404" />
			</Switch>
		</Suspense>
	);
}
