import * as actionTypes from './actionTypes';
import axios from '../../config/axios';

import { startLoading, finishLoading } from './loader';
import { errorsNotification, successNotification } from './notification';

export const setUser = (payload) => {
	return {
		type: actionTypes.SET_USER,
		payload,
	};
};

export const setLoginErr = (payload) => {
	return {
		type: actionTypes.SET_LOGIN_ERR,
		payload,
	};
};

export const setRegisterErr = (payload) => {
	return {
		type: actionTypes.SET_REGISTER_ERR,
		payload,
	};
};

export const login = (user) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.post('auth/login', { email: user.email, password: user.password })
			.then((response) => {
				localStorage.setItem('jwToken', response.data.token);
				localStorage.setItem('user', JSON.stringify(response.data.data));
				dispatch(setLoginErr(null));
				dispatch(setUser(response.data.data));
				dispatch(successNotification(response?.data?.message));
				dispatch(finishLoading());
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				dispatch(setLoginErr(error?.response?.data?.message));
				dispatch(finishLoading());
				return false;
			});
	};
};

export const register = (user, callback) => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.post('auth/register', { email: user.email, password: user.password })
			.then((response) => {
				// localStorage.setItem('jwToken', response.data.token);
				// localStorage.setItem('user', JSON.stringify(response.data.data));
				dispatch(setRegisterErr(null));
				dispatch(successNotification(response?.data?.message));
				dispatch(finishLoading());
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				dispatch(setRegisterErr(error?.response?.data?.message));
				dispatch(finishLoading());
			});
	};
};
export const getCurrentUser = () => {
	return (dispatch) => {
		// dispatch(startLoading());
		axios
			.get(`profile`)
			.then(({ data }) => {
				dispatch(setUser(data.data));
				// dispatch(finishLoading());
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				// dispatch(errorsNotification(error.response.data.message));
				// dispatch(finishLoading());
			});
	};
};

export const updateProfile = (user, callback) => {
	return (dispatch) => {
		axios
			.put(`profile`, {
				firstName: user.firstName,
				lastName: user.lastName,
				role: user.role,
				country: user.country,
				language: 'English(En)'
			})
			.then(({ data }) => {
				dispatch(setUser(data.data));
				dispatch(successNotification(data.message));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
}

export const deleteProfile = (callback, logoutCallback) => {
	return (dispatch) => {
		axios
			.delete(`profile`)
			.then(({ data }) => {
				dispatch(successNotification(data.message));
				callback();
				logoutCallback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
}

export const updateEmail = (user, callback, errorCallback) => {
	return (dispatch) => {
		axios
			.put(`auth/update-email`, {
				newEmail: user.email,
				password: user.password,
			})
			.then(({ data }) => {
				dispatch(setUser(data.data));
				dispatch(successNotification(data.message));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				errorCallback();
			});
	};
}

export const updatePassword = (user, callback, errorCallback) => {
	return (dispatch) => {
		axios
			.put(`auth/update-password`, {
				newPassword: user.newPassword,
				password: user.password,
			})
			.then(({ data }) => {
				// dispatch(setUser(data.data));
				dispatch(successNotification(data.message));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				errorCallback();
			});
	};
}