/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../../../../_metronic/layout/components/ContentLoader';
import { getApiKeys } from '../../../../store/actions/apiKeys';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../../../store/reducers';
import { createPage } from '../../../../store/actions/pages';
import clsx from 'clsx';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
	name: Yup.string().required('Name is required'),
	slug: Yup.string().required('Slug is required'),
});

const initialValues = {
	apiKey: '',
	pageType: 'site-pages',
	name: '',
	slug: '',
	language: 'en',
	metaDescription: '',
};

const PageCreatePage = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const listedDatabases = useSelector<RootState>((state) => state.databases.listedDatabases) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const [loading, setLoading] = useState(true);

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const formik = useFormik({
		initialValues,
		validationSchema: listingSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			dispatch(createPage(values, createPageCallback, () => setLoading(false)));
		},
	});

	const createPageCallback = () => {
		setLoading(false);
		history.push('/apps/pages/list');
	};

	useEffect(() => {
		setLoading(true);
		dispatch(getApiKeys(() => setLoading(false)));
		// eslint-disable-next-line
	}, []);

	return (
		<div className="database-listing">
			{!listedDatabases && !loading && (
				<div className="card-header border-0 px-7 py-6">
					<form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Hubspot API Key</label>
							<select
								className="form-select form-select-solid"
								{...formik.getFieldProps('apiKey')}
								disabled={apiKeys.length === 0}
							>
								<option disabled selected>
									Select key...
								</option>
								{apiKeys.map((key: any, index: any) => (
									<option key={index} value={key.apiKey}>
										{key.name}
									</option>
								))}
							</select>
							{formik.touched.apiKey && formik.errors.apiKey && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.apiKey}</span>
								</div>
							)}

							{apiKeys.length === 0 ? (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">No API keys added to account, to continue</label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Add new API Key
									</Link>
								</div>
							) : (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">Want to add new API key? </label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Click here
									</Link>
								</div>
							)}
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Page Type</label>
							<select
								disabled={apiKeys.length === 0}
								className="form-select form-select-solid"
								{...formik.getFieldProps('pageType')}
							>
								<option value="site-pages">Site Pages</option>
								<option value="landing-pages">Landing Pages</option>
							</select>
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Page Name</label>
							<input
								placeholder="Enter page name"
								{...formik.getFieldProps('name')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{ 'is-invalid': formik.touched.name && formik.errors.name },
									{
										'is-valid': formik.touched.name && !formik.errors.name,
									}
								)}
								onChange={(e) => {
									formik.setFieldValue('name', e.target.value);
									formik.setFieldValue('slug', e.target.value.toLowerCase().split(' ').join('-'));
								}}
								type="text"
								autoComplete="off"
							/>
							{formik.touched.name && formik.errors.name && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.name}</span>
								</div>
							)}
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">URL Slug</label>
							<input
								placeholder="Enter URL slug"
								{...formik.getFieldProps('slug')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{ 'is-invalid': formik.touched.slug && formik.errors.slug },
									{
										'is-valid': formik.touched.slug && !formik.errors.slug,
									}
								)}
								type="text"
								autoComplete="off"
							/>
							{formik.touched.slug && formik.errors.slug && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.slug}</span>
								</div>
							)}
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Language</label>
							<select
								disabled={apiKeys.length === 0}
								className="form-select form-select-solid"
								{...formik.getFieldProps('language')}
							>
								<option value="en">English</option>
								<option value="es">Spanish</option>
							</select>
						</div>

						<div className="fv-row mb-10">
							<label className="form-label fs-6 fw-bolder text-dark">Meta description</label>
							<textarea
								placeholder="Enter meta description"
								{...formik.getFieldProps('metaDescription')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{ 'is-invalid': formik.touched.metaDescription && formik.errors.metaDescription },
									{
										'is-valid': formik.touched.metaDescription && !formik.errors.metaDescription,
									}
								)}
								// type="text"
								rows={4}
								autoComplete="off"
							></textarea>
							{formik.touched.metaDescription && formik.errors.metaDescription && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.metaDescription}</span>
								</div>
							)}
						</div>

						<div className="text-center">
							<button
								type="submit"
								id="kt_sign_in_submit"
								className="btn btn-lg w-40 mb-5 btn-primary"
								disabled={!formik.isValid || apiKeys.length === 0}
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			)}

			{loading && <ContentLoader />}
		</div>
	);
};

export { PageCreatePage };
