/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { RootState } from '../../../store/reducers';
import { getRedirects, setListedRedirects } from '../../../store/actions/redirects';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const RedirectsListing: FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const listedRedirects = useSelector<RootState>((state) => state.redirects.listedRedirects) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getRedirects(values.apiKey, () => setLoading(false)));
	};

	useEffect(() => {
		setLoading(true);
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			dispatch(getRedirects(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setListedRedirects(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	const thead = [
		{
			width: 125,
			label: 'Route prefix',
		},
		{
			width: 125,
			label: 'Destination',
		},
		{
			width: 125,
			label: 'Created at',
		},
	];

	return (
		<ListingPage
			listingName="Redirects"
			listingSelector={listedRedirects}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
		>
			{listedRedirects?.length > 0 &&
				listedRedirects?.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<a href={`${item.routePrefix}`} rel="noreferrer noopener" target="_blank" className="text-info">
								{item?.routePrefix}
							</a>
						</td>

						<td>
							<div>{item.destination}</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Added</span>:{' '}
									{item.createdAt ? dayjs(item.createdAt).format('DD. MMM YYYY') : 'N/A'}
								</div>
								<div className="badge mt-2 badge-light fw-normal">
									<span className="fw-bolder">Update</span>:{' '}
									{item.updatedAt ? dayjs(item.updatedAt).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>
					</tr>
				))}
		</ListingPage>
	);
};

export default RedirectsListing;
