import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification, successNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setApiKeys = (payload) => {
	return {
		type: actionTypes.SET_API_KEYS,
		payload,
	};
};

export const getApiKeys = (callback) => {
	return (dispatch) => {
		axios
			.get('api-key')
			.then(({ data }) => {
				dispatch(setApiKeys(data.data));
				callback();
			})
			.catch((error) => {
				callback();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};

export const filterApiKeys = (queryParams, callback) => {
	return (dispatch) => {
		axios
			.post('api-key/filter', { queryParams })
			.then(({ data }) => {
				dispatch(setApiKeys(data.data));
				callback();
			})
			.catch((error) => {
				callback();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};

export const addApiKey = (params, callback) => {
	return (dispatch) => {
		axios
			.post('api-key', params)
			.then(({ data }) => {
				dispatch(getApiKeys(callback));
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callback();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};

export const deleteApiKey = (id, callback) => {
	return (dispatch) => {
		axios
			.delete(`api-key?id=${id}`)
			.then(({ data }) => {
				dispatch(getApiKeys(callback));
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callback();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};

export const setDefaultApiKey = (id, callback) => {
	return (dispatch) => {
		axios
			.put(`api-key/set-default`, { id })
			.then(({ data }) => {
				dispatch(getApiKeys(callback));
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callback();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};
