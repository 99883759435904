/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getDatabases, setListedDatabases } from '../../../store/actions/databases';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { RootState } from '../../../store/reducers';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const DatabasesListing: FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const listedDatabases = useSelector<RootState>((state) => state.databases.listedDatabases) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getDatabases(values.apiKey, () => setLoading(false)));
	};

	useEffect(() => {
		setLoading(true);

		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			dispatch(getDatabases(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setListedDatabases(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	const thead = [
		{
			width: 125,
			label: 'Table ID',
		},
		{
			width: 125,
			label: 'Label',
		},
		{
			width: 125,
			label: 'Name',
		},
		{
			width: 125,
			label: 'Entries',
		},
		{
			width: 125,
			label: 'Columns',
		},
		{
			width: 125,
			label: 'Dates',
		},
	];

	return (
		<ListingPage
			listingName="Databases"
			listingSelector={listedDatabases}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
		>
			{listedDatabases?.length > 0 &&
				listedDatabases.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<div>{item?.id}</div>
						</td>
						<td>
							<div>{item?.label}</div>
						</td>
						<td>
							<div>{item?.name}</div>
						</td>
						<td>
							<div>{item?.rowCount}</div>
						</td>
						<td>
							<div>{item?.columnCount}</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Added</span>:{' '}
									{item.createdAt ? dayjs(item.createdAt).format('DD. MMM YYYY') : 'N/A'}
								</div>
								<div className="badge mt-2 badge-light fw-normal">
									<span className="fw-bolder">Update</span>:{' '}
									{item.updatedAt ? dayjs(item.updatedAt).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>
					</tr>
				))}
		</ListingPage>
	);
};

export default DatabasesListing;
