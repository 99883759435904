import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const ContentLoader = () => {
	return (
		<div className="ContentLoader">
			<AiOutlineLoading3Quarters />
		</div>
	);
};

export default ContentLoader;
