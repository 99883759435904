import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setListedPortalOwners = (payload) => {
	return {
		type: actionTypes.SET_LISTED_PORTAL_OWNERS,
		payload,
	};
};

export const getPortalOwners = (apiKey, callback) => {
	return (dispatch) => {
		axios
			.get(`owner?apiKey=${apiKey}`)
			.then(({ data }) => {
				dispatch(setListedPortalOwners(data.data));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
};