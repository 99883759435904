import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// Apps
import { App } from './app/App';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';

// reducers
import { rootReducer } from './store/reducers';

const { PUBLIC_URL } = process.env;

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
	<MetronicI18nProvider>
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			{/* <PersistGate persistor={persistor} loading={<div>Loading...</div>}> */}
			<App basename={PUBLIC_URL} />
			{/* </PersistGate> */}
		</Provider>
	</MetronicI18nProvider>,
	document.getElementById('root')
);
