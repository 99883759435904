/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ApiKeys from './components/ApiKeys';
import { PageTitle } from '../../../_metronic/layout/core';

const ApiKeysWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>API Keys</PageTitle>
			<ApiKeys />
		</>
	);
};

export default ApiKeysWrapper;
