import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setLogs = (payload) => {
	return {
		type: actionTypes.SET_LOGS,
		payload,
	};
};

export const setHomeLogs = (payload) => {
	return {
		type: actionTypes.SET_HOME_LOGS,
		payload,
	};
};

export const setLogsTotal = (payload) => {
	return {
		type: actionTypes.SET_LOGS_TOTAL,
		payload,
	};
};

export const setLogsLastPage = (payload) => {
	return {
		type: actionTypes.SET_LOGS_LAST_PAGE,
		payload,
	};
};

export const getLogs = (callback) => {
	return (dispatch) => {
		// dispatch(startLoading());
		axios
			.get('log')
			.then(({ data }) => {
				dispatch(setLogs(data.data));
				// dispatch(finishLoading());
				callback();
			})
			.catch((error) => {
				// dispatch(finishLoading());
				callback();
			});
	};
};

export const getLogsFilter = (queryParams, callback, isHome) => {
	return (dispatch) => {
		axios
			.post('log/filter', { queryParams })
			.then(({ data }) => {
				if (isHome) {
					dispatch(setHomeLogs(data.data));
				} else {
					dispatch(setLogs(data.data));
					dispatch(setLogsTotal(data.total));
					dispatch(setLogsLastPage(Math.ceil(data.total / queryParams.pageSize)));
				}

				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
};
