import { combineReducers } from 'redux';

// reducers
import loader from './loader';
import auth from './auth';
import logs from './logs';
import notification from './notification';
import pages from './pages';
import domains from './domains';
import databases from './databases';
import users from './users';
import apiKeys from './apiKeys';
import templates from './templates';
import redirects from './redirects';
import portalOwners from './portalOwners';
import blogs from './blogs';
import files from './files';
import analytics from './analytics';

export const rootReducer = combineReducers({
	loader,
	auth,
	logs,
	notification,
	pages,
	domains,
	users,
	apiKeys,
	databases,
	templates,
	redirects,
	portalOwners,
	blogs,
	files,
	analytics,
});

export type RootState = ReturnType<typeof rootReducer>;
