/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../../../../_metronic/layout/components/ContentLoader';
import { addApiKey, deleteApiKey, setDefaultApiKey, getApiKeys } from '../../../../store/actions/apiKeys';
import { KTSVG } from '../../../../_metronic/helpers';
import { RootState } from '../../../../store/reducers';
import { DeletePopup } from '../../../modules/popups/DeletePopup/DeletePopup';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required'),
	name: Yup.string().required('Page Type is required'),
});

const initialValues = {
	apiKey: '',
	name: '',
};

type Props = {
	apiKeys: any;
	loading: boolean;
	deleteApikeyHandler: Function;
	setDefaultApikeyHandler: Function;
};

const ApiKeyTable: React.FC<Props> = ({ apiKeys, loading, deleteApikeyHandler, setDefaultApikeyHandler }) => {
	const [deletePopupValue, setDeletePopup] = useState(null);

	return (
		<div className="card mt-5 mb-lg-10">
			{/* begin::Card header */}
			<div className="card-header">
				{/* begin::Heading */}
				<div className="card-title">
					<h3>API Keys</h3>
				</div>
				{/* end::Heading */}
				{/* begin::Toolbar */}
				<div className="card-toolbar">
					<div className="my-1 me-4"></div>
				</div>
				{/* end::Toolbar */}
			</div>
			{/* end::Card header */}
			{/* begin::Card body */}
			<div className="card-body p-0">
				{/* begin::Table wrapper */}
				<div className="table-responsive">
					{loading && (
						<div style={{ minHeight: 150, position: 'relative', width: '100%' }}>
							<ContentLoader />
						</div>
					)}
					{/* begin::Table */}
					{!loading && (
						<table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
							{/* begin::Thead */}
							<thead className="border-gray-200 fs-5 fw-bold bg-lighten">
								<tr>
									<th className="min-w-250px">Name</th>
									<th className="min-w-150px">Portal ID</th>
									<th className="min-w-250px">API Key</th>
									<th className="min-w-125px ps-4">Default</th>
									<th className="min-w-100px text-end">Actions</th>
								</tr>
							</thead>
							{/* end::Thead */}
							{/* begin::Tbody */}

							<tbody className="fw-6 fw-bold text-gray-600">
								{apiKeys.length > 0 &&
									apiKeys.map((item: any, index: any) => (
										<tr key={index}>
											<td>
												<div>
													{item?.name}
													{item?.default && <span className="badge badge-primary ms-3">Default</span>}
												</div>
											</td>
											<td>
												<div>{item?.portalId}</div>
											</td>
											<td>
												<div>{item?.apiKey?.replace(/[^-]./g, '##')}</div>
											</td>

											<td>
												<div
													className="form-check form-check-solid fv-row"
													onClick={() => !item?.default && setDefaultApikeyHandler(item._id)}
													title="Set API Key as default"
												>
													{/* <KTSVG path="/media/icons/duotune/abstract/abs024.svg" className="svg-icon-info svg-icon-2" /> */}
													<div className="form-check form-check-solid fv-row">
														<input
															className="form-check-input"
															type="checkbox"
															checked={item?.default}
															disabled={item?.default}
															readOnly
														/>
													</div>
												</div>
											</td>
											<td className="text-end">
												<div
													className="btn btn-icon  btn-light-danger btn-active-danger btn-sm ms-3"
													// onClick={() => deleteApikeyHandler(item._id)}
													onClick={() => setDeletePopup(item._id)}
													title="Delete API Key"
												>
													<KTSVG
														path="/media/icons/duotune/general/gen027.svg"
														className="svg-icon-danger svg-icon-2"
													/>
												</div>
											</td>
										</tr>
									))}
							</tbody>
							{/* end::Tbody */}
						</table>
					)}
					{/* end::Table */}
				</div>
				{/* end::Table wrapper */}
			</div>
			{/* end::Card body */}
			<DeletePopup
				deleteType="API Key"
				id={deletePopupValue}
				closePopup={() => setDeletePopup(null)}
				confirmFunction={(callback: any) => deleteApikeyHandler(deletePopupValue, callback)}
			/>
		</div>
	);
};

const ApiKeys = () => {
	const dispatch = useDispatch();

	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const [loading, setLoading] = useState(false);

	const deleteApikeyHandler = (id: any, callback: any) => {
		dispatch(deleteApiKey(id, callback));
	};

	const formik = useFormik({
		initialValues,
		validationSchema: listingSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			dispatch(addApiKey({ apiKey: values.apiKey, name: values.name }, () => setLoading(false)));
		},
	});

	useEffect(() => {
		setLoading(true);
		dispatch(getApiKeys(() => setLoading(false)));
		// eslint-disable-next-line
	}, []);

	const setDefaultApikeyHandler = (id: any) => {
		setLoading(true);
		dispatch(setDefaultApiKey(id, () => setLoading(false)));
	};

	return (
		<div className="pages-listing">
			<div className="card-header border-0 px-7 py-6">
				<form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
					<div className="fv-row mb-10">
						<label className="form-label fw-bolder text-dark">Hubspot API Key</label>
						<input
							placeholder="API key"
							{...formik.getFieldProps('apiKey')}
							className={clsx(
								'form-control form-control-solid',
								{ 'is-invalid': formik.touched.apiKey && formik.errors.apiKey },
								{
									'is-valid': formik.touched.apiKey && !formik.errors.apiKey,
								}
							)}
							type="text"
							autoComplete="off"
						/>
						<a
							href="https://app.hubspot.com/l/api-key/"
							rel="noreferrer noopener"
							target="_blank"
							className="my-3 d-block"
						>
							Get API key
						</a>
						{formik.touched.apiKey && formik.errors.apiKey && (
							<div className="fv-plugins-message-container">
								<span role="alert">{formik.errors.apiKey}</span>
							</div>
						)}
					</div>

					<div className="fv-row mb-10">
						<label className="form-label fw-bolder text-dark">Name</label>
						<input
							placeholder="Enter name"
							{...formik.getFieldProps('name')}
							className={clsx(
								'form-control form-control-solid',
								{ 'is-invalid': formik.touched.name && formik.errors.name },
								{
									'is-valid': formik.touched.name && !formik.errors.name,
								}
							)}
							type="text"
							autoComplete="off"
						/>
						{formik.touched.name && formik.errors.name && (
							<div className="fv-plugins-message-container">
								<span role="alert">{formik.errors.name}</span>
							</div>
						)}
					</div>

					<div className="text-center">
						<button
							type="submit"
							id="kt_sign_in_submit"
							className="btn btn-lg btn-primary w-40 mb-5"
							disabled={!formik.isValid}
						>
							Submit
						</button>
					</div>
				</form>
			</div>

			<ApiKeyTable
				apiKeys={apiKeys}
				loading={loading}
				deleteApikeyHandler={deleteApikeyHandler}
				setDefaultApikeyHandler={setDefaultApikeyHandler}
			/>
		</div>
	);
};

export default ApiKeys;
