/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import React from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../helpers';

type Props = {
	show: boolean;
	handleClose: () => void;
	start: any;
	end: any;
	setStart: any;
	setEnd: any;
	isValid: boolean;
	onSubmit: any;
};

const AnalyticsModal: React.FC<Props> = ({ show, handleClose, start, end, setStart, setEnd, isValid, onSubmit }) => {
	// const dispatch = useDispatch();

	return (
		<Modal
			id="kt_modal_create_app"
			tabIndex={-1}
			aria-hidden="true"
			dialogClassName="modal-dialog-centered mw-1000px h-auto"
			show={show}
			onHide={handleClose}
		>
			<div className="container-xxl px-5 py-5">
				<div className="modal-header py-2 d-flex justify-content-between border-0">
					{/* begin::Close */}
					<h3>Get analytics for selected pages</h3>
					<div className="btn btn-icon btn-sm btn-light-primary" onClick={handleClose}>
						<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
					</div>
					{/* end::Close */}
				</div>
				<div className="separator mx-1 mt-4 mb-0"></div>

				<div className="modal-body">
					<div className="row">
						<div className="col-sm-6">
							<label className="form-label fs-6 fw-bolder text-dark">Start</label>
							<input
								type="date"
								className="form-control form-control-lg form-control-solid mb-4"
								value={start}
								onChange={(e) => setStart(e.target.value)}
							/>
						</div>
						<div className="col-sm-6">
							<label className="form-label fs-6 fw-bolder text-dark">End</label>
							<input
								type="date"
								className="form-control form-control-lg form-control-solid mb-4"
								value={end}
								onChange={(e) => setEnd(e.target.value)}
							/>
						</div>
					</div>
					<div className="text-center">
						<button
							type="submit"
							id="kt_sign_in_submit"
							className="btn btn-md w-40 mb-3 btn-primary"
							disabled={!isValid}
							onClick={onSubmit}
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export { AnalyticsModal };
