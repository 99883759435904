import React, { FC, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { getCurrentUser } from '../store/actions/auth';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider } from '../_metronic/layout/core';
import { FallbackView } from '../_metronic/partials';
import { Routes } from './routing/Routes';
import Notifications from '../_metronic/layout/components/Notifications';
import { RootState } from '../store/reducers';

import 'react-toastify/dist/ReactToastify.css';

type Props = {
	basename: string;
};

const App: FC<Props> = ({ basename }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector<RootState>((state) => state.loader);

	React.useEffect(() => {
		if (localStorage.getItem('user')) {
			dispatch(getCurrentUser());
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Suspense fallback={<FallbackView />}>
			{isLoading && <FallbackView />}
			<BrowserRouter basename={basename}>
				<I18nProvider>
					<LayoutProvider>
						<Notifications />
						{/* <AuthInit> */}
						<Routes />
						{/* </AuthInit> */}
					</LayoutProvider>
				</I18nProvider>
			</BrowserRouter>
		</Suspense>
	);
};

export { App };
