/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React from 'react';
import { toAbsoluteUrl } from '../../../helpers';

type Props = {
	className: string;
	imagePath?: string;
	innerPadding?: string;
	color?: string;
};

const Heading: React.FC<Props> = ({ className, imagePath = '', innerPadding = '', color = 'primary' }) => {
	return (
		<div className={`card overflow-hidden ${className}`}>
			<div className={`card-body p-0 d-flex card-rounded`}>
				<div className="py-18 px-12">
					<h3 className="fs-2x">
						<span className="text-dark fw-bolder">
							Decker | Portal
						</span>
					</h3>
					<div className={clsx('fs-3', `text-${color}`)}>Welcome to the decker portal control managment system</div>
				</div>
			</div>
			<img src={toAbsoluteUrl('/media/svg/illustrations/hubspotLogo.svg')} alt="" className="heading-image d-none d-md-block" />
		</div>
	);
};

export { Heading };
