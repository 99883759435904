/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import ListingPage from '../../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
// import { getDatabases, setListedDatabases } from '../../../../store/actions/databases';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../../store/actions/apiKeys';
import { RootState } from '../../../../store/reducers';
import { KTSVG } from '../../../../_metronic/helpers';
import { getBlogTags, setBlogTags } from '../../../../store/actions/blogs';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const BlogTags: FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	// const [deletePopupValue, setDeletePopupValue] = useState(null);
	const blogTags = useSelector<RootState>((state) => state.blogs.blogTags) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getBlogTags(values.apiKey, () => setLoading(false)));
	};

	useEffect(() => {
		setLoading(true);

		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			dispatch(getBlogTags(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setBlogTags(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	// const formAdditional = (formik: any) => (
	// 	<div className="fv-row mb-10">
	// 		<label className="form-label fs-6 fw-bolder text-dark">Page Type</label>
	// 		<select
	// 			disabled={apiKeys.length === 0}
	// 			className="form-select form-select-solid"
	// 			{...formik.getFieldProps('pageType')}
	// 		>
	// 			<option value="site-pages">Site Pages</option>
	// 			<option value="landing-pages">Landing Pages</option>
	// 		</select>
	// 	</div>
	// );

	const thead = [
		{
			width: 125,
			label: 'Tag ID',
		},
		{
			width: 125,
			label: 'Name',
		},
		{
			width: 125,
			label: 'Language',
		},
		{
			width: 125,
			label: 'Dates',
		},
		{
			width: 100,
			label: 'Actions',
			className: 'text-end',
		},
	];

	return (
		<ListingPage
			listingName="Blog Tags"
			listingSelector={blogTags}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
			addNewLink="#"
		>
			{blogTags?.length > 0 &&
				blogTags.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<div>{item?.id}</div>
						</td>

						<td>
							<div>{item?.name}</div>
						</td>

						<td>
							<div>{item?.langauge || 'N/A'}</div>
						</td>

						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Added</span>:{' '}
									{item.created ? dayjs(item.created).format('DD. MMM YYYY') : 'N/A'}
								</div>
								<div className="badge mt-2 badge-light fw-normal">
									<span className="fw-bolder">Update</span>:{' '}
									{item.updated ? dayjs(item.updated).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>

						<td className="text-end">
							<div
								className="btn btn-icon btn-light-danger btn-active-danger btn-sm"
								title="Delete"
								// onClick={() => setDeletePopupValue({formik })}
							>
								<KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-danger svg-icon-4" />
							</div>
						</td>
					</tr>
				))}
		</ListingPage>
	);
};

export default BlogTags;
