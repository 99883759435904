/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
// import { getDatabases, setListedDatabases } from '../../../store/actions/databases';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { RootState } from '../../../store/reducers';
import { getSearchedPages, setSearchedPages } from '../../../store/actions/pages';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import clsx from 'clsx';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
	searchTerm: Yup.string().required('Search is required'),
});

const initialValues = {
	apiKey: '',
	searchTerm: '',
};

// const calcScore = (score: any) => {
// 	return Math.floor(score * 100) + ' %';
// };

const types: any = {
	SITE_PAGE: { name: 'PAGE', color: 'badge-light-info' },
	BLOG_POST: { name: 'BLOG', color: 'badge-light-success' },
	LISTING_PAGE: { name: 'BLOG LISTING', color: 'badge-light-warning' },
};

const SearchContent: FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const searchedPages = useSelector<RootState>((state) => state.pages.searchedPages) as any;
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getSearchedPages(values.apiKey, values?.searchTerm, 100, () => setLoading(false)));
	};

	useEffect(() => {
		setLoading(true);
		dispatch(setSearchedPages(null));

		if (!apiKeys.length) {
			dispatch(getApiKeys(() => setLoading(false)));
		} else {
			setLoading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	// const setBgColorStatus = (status: string) => {
	// 	if (status === 'PUBLISHED') {
	// 		return 'badge-light-success';
	// 	} else {
	// 		return 'badge-light-warning';
	// 	}
	// };

	const formAdditional = (formik: any) => (
		<div className="fv-row mb-10">
			<label className="form-label fs-6 fw-bolder text-dark">Search term</label>
			<input
				placeholder="Enter search term"
				{...formik.getFieldProps('searchTerm')}
				className={clsx(
					'form-control form-control-lg form-control-solid',
					{ 'is-invalid': formik.touched.searchTerm && formik.errors.searchTerm },
					{
						'is-valid': formik.touched.searchTerm && !formik.errors.searchTerm,
					}
				)}
				type="text"
				autoComplete="off"
			/>
			{formik.touched.searchTerm && formik.errors.searchTerm && (
				<div className="fv-plugins-message-container">
					<span role="alert">{formik.errors.searchTerm}</span>
				</div>
			)}
		</div>
	);

	const thead = [
		{
			width: 125,
			label: 'Title',
		},
		{
			width: 150,
			label: 'Score',
		},

		{
			width: 125,
			label: 'Author',
		},
		{
			width: 125,
			label: 'Type',
		},
		{
			width: 125,
			label: 'Dates',
		}
	];

	return (
		<ListingPage
			listingName="Search Content"
			listingSelector={searchedPages}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
			formAdditional={formAdditional}
			// addNewLink="/apps/pages/create"
		>
			{searchedPages?.length > 0 &&
				searchedPages.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<div className="d-flex align-items-center">
								<img
									src={
										item.featuredImageUrl
											? item.featuredImageUrl
											: toAbsoluteUrl('/media/pages/default-placeholder.png')
									}
									alt=""
									className="page-image"
								/>
								<div className="d-flex justify-content-start flex-column">
									<a href={item?.url} rel="noreferrer noopener" target="_blank" className="">
										{parse(item?.title)}
									</a>
								</div>
							</div>
						</td>
						<td>
							<div>{item?.score}</div>
						</td>
						<td>
							<div>{item.authorFullName ? item.authorFullName : ''}</div>
						</td>

						<td>
							<div className={`badge ${types[item?.type] ? types[item?.type].color : 'badge-light-warning'}`}>
								{types[item?.type] ? types[item?.type].name : item?.type}
							</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Published</span>:{' '}
									{item.publishedDate ? dayjs(item.publishedDate).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>
					</tr>
				))}
		</ListingPage>
	);
};

export default SearchContent;
