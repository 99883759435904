/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { Heading, StatisticsWidget1, StatisticsWidget2 } from '../../../_metronic/partials/widgets';
import dayjs from 'dayjs';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { UserModel } from '../../modules/auth/models/UserModel';
import { DashboardUsers } from './components/DashboardUsers';
import { DashboardActivity } from './components/DashboardActivity';

const DashboardWrapper: FC = () => {
	const intl = useIntl();
	const user = useSelector<RootState>((state) => state.auth.account, shallowEqual) as UserModel;

	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
			<>
				{/* begin::Row */}
				<div className="row gy-5 g-xl-8">
					<div className="col-xl-12">
						<Heading className="card-xl-stretch mb-5 mb-xl-8" />
					</div>
				</div>
				{/* end::Row */}

				{/* begin::Row */}
				<div className="row gy-5 g-xl-8">
					<div className="col-xl-5">
						<StatisticsWidget1
							className="card-xl-stretch  mb-xl-8"
							image="abstract-2.svg"
							title="Let's get started"
							time={dayjs(new Date()).format('DD MMM YYYY')}
							description="Great time to do something amazing!"
						/>
					</div>
					<div className="col-xl-7">
						<StatisticsWidget2
							className="card-xl-stretch mb-5 mb-xl-8"
							avatar="/media/svg/avatars/hubspot-avatar.svg"
							title={`${user?.firstName && user?.lastName ? user?.firstName + ' ' + user?.lastName : user?.email ? user.email : ''}`}
							description={user?.role}
						/>
					</div>
				</div>
				{/* end::Row */}

				{/* begin::Row */}
				<div className="row gy-5 g-xl-8">
					<div className="col-xl-8">
						<DashboardActivity className="card-xl-stretch mb-xl-8" />
					</div>
					<div className="col-xl-4">
						<DashboardUsers className="card-xl-stretch mb-xl-8" />
					</div>
				</div>
				{/* end::Row */}
			</>
		</>
	);
};

export { DashboardWrapper };
