import React from 'react';
import { PageFindAndReplace } from './components/PageFindAndReplace';
import { PageTitle } from '../../../_metronic/layout/core';

const PageFindAndReplaceWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Page Find and Replace</PageTitle>
			<PageFindAndReplace />
		</>
	);
};

export default PageFindAndReplaceWrapper;
