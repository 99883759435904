import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification, successNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setListedPages = (payload) => {
	return {
		type: actionTypes.SET_LISTED_PAGES,
		payload,
	};
};

export const setSearchedPages = (payload) => {
	return {
		type: actionTypes.SET_SEARCHED_PAGES,
		payload,
	};
};

export const getListedPages = (apiKey, pageType, callback) => {
	return (dispatch) => {
		axios
			.get(`page?apiKey=${apiKey}&pageType=${pageType}`)
			.then(({ data }) => {
				dispatch(setListedPages(data.data));
				callback();
			})
			.catch((error) => {
				callback();
			});
	};
};

export const getSearchedPages = (apiKey, searchTerm, limit, callback) => {
	return (dispatch) => {
		axios
			.post('search', { searchTerm, limit, apiKey })
			.then(({ data }) => {
				dispatch(setSearchedPages(data.data));
				callback();
			})
			.catch((error) => {
				callback();
			});
	};
};

export const findAndReplaceWithPage = (pageData, callback, callbackError) => {
	return (dispatch) => {
		axios
			.post('page/find-and-replace', pageData)
			.then(({ data }) => {
				dispatch(getListedPages(pageData.apiKey, pageData.pageType, () => {}));
				callback();
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callbackError();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};


export const createPage = (pageData, callback, callbackError) => {
	return (dispatch) => {
		axios
			.post('page', pageData)
			.then(({ data }) => {
				dispatch(getListedPages(pageData.apiKey, pageData.pageType, () => {}));
				callback();
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callbackError();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};

export const duplicatePages = (pageData, callback, callbackError) => {
	return (dispatch) => {
		axios
			.post('page/duplicate', pageData)
			.then(({ data }) => {
				callback();
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callbackError();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};
