import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
// import { errorsNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setFiles = (payload) => {
	return {
		type: actionTypes.SET_FILES,
		payload,
	};
};

export const getFiles = (apiKey, callback) => {
	return (dispatch) => {
		axios
			.get(`file?apiKey=${apiKey}`)
			.then(({ data }) => {
				dispatch(setFiles(data));
				callback && callback();
			})
			.catch((error) => {
				callback && callback();
			});
	};
};
