/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react';
import './DeletePopup.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

type Props = {
	deleteType: string;
	id: any;
	confirmFunction: any;
	closePopup: any;
};

const MySwal = withReactContent(Swal);

export const DeletePopup: FC<Props> = ({ deleteType, id, confirmFunction, closePopup }) => {
	useEffect(() => {
		if (id) {
			MySwal.fire({
				text: `Are you sure you want to delete ${deleteType}`,
				icon: 'warning',
				showCancelButton: true,
				buttonsStyling: false,
				confirmButtonText: 'Yes, delete!',
				cancelButtonText: 'No, cancel',
				customClass: {
					confirmButton: 'btn fw-bold btn-danger',
					cancelButton: 'btn fw-bold btn-active-light-primary',
				},
			}).then(function (result) {
				if (result.value) {
					MySwal.fire({
						text: `Deleting ${deleteType}`,
						icon: 'info',
						buttonsStyling: false,
						showConfirmButton: false,
					});
					confirmFunction(callbackFunction);
				}
				if (result.isDismissed) {
					closePopup();
				}
			});
		}
		// eslint-disable-next-line
	}, [id]);

	const callbackFunction = () => {
		MySwal.fire({
			text: `You have deleted ${deleteType}!`,
			icon: 'success',
			buttonsStyling: false,
			confirmButtonText: 'Ok, got it!',
			customClass: {
				confirmButton: 'btn fw-bold btn-primary',
			},
		});
	};

	return <div></div>;
};
