import * as actionTypes from '../actions/actionTypes';

const initialState = {
	listedAnalytics: null,
	analyticsType: 'sessions',
	analyticsContent: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ANALYTICS:
			return {
				...state,
				listedAnalytics: action.payload,
			};
		case actionTypes.SET_ANALYTICS_TYPE:
			return {
				...state,
				analyticsType: action.payload,
			};
		case actionTypes.SET_ANALYTICS_CONTENT:
			return {
				...state,
				analyticsContent: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
