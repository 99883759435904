import { toAbsoluteUrl } from '../../../helpers';

export function FallbackView() {
	return (
		<div className="splash-screen">
			<img src={toAbsoluteUrl('/media/logos/logo-icon.png')} alt="Start logo" />
			<div className="progress w-150px h-10px">
				<div
					className="progress-bar"
					role="progressbar"
				></div>
			</div>
		</div>
	);
}
