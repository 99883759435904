/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { KTSVG } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { getApiKeys } from '../../../../store/actions/apiKeys';
import { getFiles, setFiles } from '../../../../store/actions/files';
import dayjs from 'dayjs';
import ContentLoader from '../../../../_metronic/layout/components/ContentLoader';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required'),
});

const initialValues = {
	apiKey: '',
};

export default function Files() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;
	const files = useSelector<RootState>((state) => state.files.files) as any;

	const formik = useFormik({
		initialValues,
		validationSchema: listingSchema,
		onSubmit: (values) => {
			setLoading(true);
			dispatch(getFiles(values.apiKey, () => setLoading(false)));
		},
	});

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	useEffect(() => {
		setLoading(true);
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			setLoading(true);
			dispatch(getFiles(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setFiles(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	return (
		<>
			<div className="card-header px-7 py-6">
				<form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
					<div>
						<label className="form-label fs-6 fw-bolder text-dark">Hubspot API Key</label>
						<select
							className="form-select form-select-solid"
							{...formik.getFieldProps('apiKey')}
							disabled={apiKeys.length === 0}
						>
							<option disabled selected>Select key...</option>
							{apiKeys.map((key: any, index: any) => (
								<option key={index} value={key.apiKey}>
									{key.name}
								</option>
							))}
						</select>
						{formik.touched.apiKey && formik.errors.apiKey && (
							<div className="fv-plugins-message-container">
								<span role="alert">{formik.errors.apiKey}</span>
							</div>
						)}

						{apiKeys.length === 0 ? (
							<div className="fv-row mt-3">
								<label className="form-label fs-6 text-muted">No API keys added to account, to continue</label>
								<Link to="/api-keys" className="text-primary fs-6">
									{' '}
									Add new API Key
								</Link>
							</div>
						) : (
							<div className="fv-row mt-3">
								<label className="form-label fs-6 text-muted">Want to add new API key? </label>
								<Link to="/api-keys" className="text-primary fs-6">
									{' '}
									Click here
								</Link>
							</div>
						)}
					</div>

					<div className="separator mx-1 my-8"></div>

					<div className="text-center">
						<button
							type="submit"
							id="kt_sign_in_submit"
							className="btn btn-md w-40 mb-3 btn-primary"
							disabled={!formik.isValid || apiKeys.length === 0}
						>
							Submit
						</button>
					</div>
				</form>
			</div>

			{!loading && (
				<>
					{files?.folders?.length ? (
						<>
							<div className="d-flex flex-wrap flex-stack my-6">
								<h3 className="fw-bolder my-2">Folders</h3>
							</div>
							<div className="row g-6 g-xl-9 mb-6 mb-xl-9">
								{files?.folders.map((folder: any) => (
									<div className="col-12 col-sm-12 col-xl-4">
										<Card4
											href="#"
											icon="/media/svg/files/folder-document.svg"
											title={folder?.name}
											description={`Updated: ${dayjs(folder.updated).fromNow()}`}
										/>
									</div>
								))}
							</div>
						</>
					) : null}

					{files?.files?.length ? (
						<>
							<div className="d-flex flex-wrap flex-stack my-6">
								<h3 className="fw-bolder my-2">Files</h3>
							</div>
							<div className="row g-6 g-xl-9 mb-6 mb-xl-9">
								{files?.files.map((file: any) => (
									<div className="col-12 col-sm-12 col-xl-3">
										<Card4
											href="#"
											icon={
												file.type === 'IMG'
													? file.url
													: file?.encoding
													? `/media/svg/files/${file?.encoding}.svg`
													: '/media/svg/files/file-classic.svg'
											}
											title={file?.name?.length > 20 ? file.name.slice(0, 30) + '...' : file.name}
											description={`Updated: ${dayjs(file.updated).fromNow()}`}
										/>
									</div>
								))}
							</div>
						</>
					) : null}
				</>
			)}
			{loading && (
				<div style={{ minHeight: 350, position: 'relative', width: '100%' }}>
					<ContentLoader />
				</div>
			)}
		</>
	);
}
