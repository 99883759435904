/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { PageCreatePage } from './components/PageCreatePage';
import { PageTitle } from '../../../_metronic/layout/core';

const PageCreateWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Create Page</PageTitle>
			<PageCreatePage />
		</>
	);
};

export default PageCreateWrapper;
