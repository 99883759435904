/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../store/actions/auth';
import { RootState } from '../../../../store/reducers';
import { toAbsoluteUrl } from '../../../helpers';
import { Languages } from './Languages';
import { setApiKeys } from '../../../../store/actions/apiKeys';

const HeaderUserMenu: FC = () => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.account, shallowEqual) as UserModel;

	const dispatch = useDispatch();
	const logout = () => {
		dispatch(setApiKeys([]));
		dispatch(setUser(null));
		localStorage.clear();
	};

	return (
		<div
			className="overflow-hidden menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px"
			data-kt-menu="true"
		>
			<div className="menu-item px-3">
				<div className="menu-content d-flex align-items-center px-3">
					<div className="symbol symbol-50px me-5">
						<img alt="Logo" src={user?.pic ? user.pic : toAbsoluteUrl('/media/avatars/blank.png')} />
					</div>

					<div className="d-flex flex-column">
						<div className="fw-bolder d-flex align-items-center fs-5">
							{user?.firstName} {user?.lastName}
						</div>
						<a href="#" className="fw-bold text-muted text-hover-primary fs-8 header-email">
							{user?.email}
						</a>
					</div>
				</div>
			</div>

			{/* <div className="menu-item px-5">
				<Link to="/apps/projects" className="menu-link px-5">
					<span className="menu-text">My Projects</span>
					<span className="menu-badge">
						<span className="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
					</span>
				</Link>
			</div> */}

			<div className="separator my-2"></div>



			<div className="menu-item px-5 my-1">
				<Link to="/setup/account/overview" className="menu-link px-5">
					My profile
				</Link>
			</div>

			<div className="separator my-2"></div>

			<Languages />

			<div className="menu-item px-5">
				<a onClick={logout} className="menu-link px-5">
					Sign Out
				</a>
			</div>
		</div>
	);
};

export { HeaderUserMenu };
