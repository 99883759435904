/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { login } from '../../../../store/actions/auth';

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
});

const initialValues = {
	email: '',
	password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	// const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			dispatch(login({ email: values.email, password: values.password }));
			// setLoading(true);
			// setTimeout(() => {
			// 	login(values.email, values.password)
			// 		.then(({ data: { accessToken } }) => {
			// 			setLoading(false);
			// 			dispatch(auth.actions.login(accessToken));
			// 		})
			// 		.catch(() => {
			// 			setLoading(false);
			// 			setSubmitting(false);
			// 			setStatus('The login detail is incorrect');
			// 		});
			// }, 1000);
		},
	});

	return (
		<form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
			{/* begin::Heading */}
			<div className="text-center mb-10">
				<h1 className="text-dark mb-3">Sign In to Decker portal</h1>

				<div className="text-gray-400 fw-bold">
					Don't have an account?
					<Link to="/auth/registration" className="link-primary fw-bolder" style={{ marginLeft: '5px' }}>
						Sign up
					</Link>
				</div>
			</div>
			{/* begin::Heading */}

			{formik.status && (
				<div className="mb-lg-15 alert alert-danger">
					<div className="alert-text font-weight-bold">{formik.status}</div>
				</div>
			)}

			{/* begin::Form group */}
			<div className="fv-row mb-10">
				<label className="form-label fw-bolder text-dark">Email</label>
				<input
					placeholder="user123@gmail.com"
					{...formik.getFieldProps('email')}
					className={clsx(
						'form-control form-control-solid',
						{ 'is-invalid': formik.touched.email && formik.errors.email },
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						}
					)}
					type="email"
					name="email"
				/>
				{formik.touched.email && formik.errors.email && (
					<div className="fv-plugins-message-container">
						<span role="alert">{formik.errors.email}</span>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Form group */}
			<div className="fv-row mb-10">
				<div className="d-flex justify-content-between mt-n5">
					<div className="d-flex flex-stack mb-2">
						{/* begin::Label */}
						<label className="form-label fw-bolder text-dark mb-0">Password</label>
						{/* end::Label */}
						{/* begin::Link */}
						<Link to="/auth/forgot-password" className="link-primary fw-bolder" style={{ marginLeft: '5px' }}>
							Forgot Password ?
						</Link>
						{/* end::Link */}
					</div>
				</div>
				<input
					type="password"
					placeholder="***********"
					autoComplete="off"
					{...formik.getFieldProps('password')}
					className={clsx(
						'form-control form-control-solid',
						{
							'is-invalid': formik.touched.password && formik.errors.password,
						},
						{
							'is-valid': formik.touched.password && !formik.errors.password,
						}
					)}
				/>
				{formik.touched.password && formik.errors.password && (
					<div className="fv-plugins-message-container">
						<div className="fv-help-block">
							<span role="alert">{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Action */}
			<div className="text-center">
				<button
					type="submit"
					id="kt_sign_in_submit"
					className="btn btn-lg btn-primary w-100 mb-5"
					disabled={!formik.isValid}
				>
					Login
					{/* {!loading && <span className="indicator-label">Continue</span>}
					{loading && (
						<span className="indicator-progress" style={{ display: 'block' }}>
							Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					)} */}
				</button>
			</div>
			{/* end::Action */}
		</form>
	);
}
