import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { getPortalOwners, setListedPortalOwners } from '../../../store/actions/portalOwners';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const PortalOwnersListing = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;
	const listedPortalOwners = useSelector<RootState>((state) => state.portalOwners.listedPortalOwners) as any;

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getPortalOwners(values.apiKey, () => setLoading(false)));
	};

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	useEffect(() => {
		setLoading(true);
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			dispatch(getPortalOwners(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setListedPortalOwners(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	const thead = [
		{
			width: 125,
			label: 'User ID',
		},
		{
			width: 125,
			label: 'Name',
		},
		{
			width: 125,
			label: 'Email',
		},
		{
			width: 125,
			label: 'Status',
		},
		{
			width: 150,
			label: 'Add Date',
		},
	];

	return (
		<ListingPage
			listingName="Portal Owners"
			listingSelector={listedPortalOwners}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
		>
			{listedPortalOwners?.length > 0 &&
				listedPortalOwners.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<div>{item.userId}</div>
						</td>
						<td>
							<div>
								{item.firstName && item.firstName} {item.lastName && item.lastName}
							</div>
						</td>
						<td>
							<div>{item.email}</div>
						</td>
						<td>
							<div className={`badge ${!item.archived ? 'badge-light-success' : 'badge-light-danger'}`}>
								{!item.archived ? 'Active' : 'Archived'}
							</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Added</span>:{' '}
									{item.createdAt ? dayjs(item.createdAt).format('DD. MMM YYYY') : 'N/A'}
								</div>
								<div className="badge mt-2 badge-light fw-normal">
									<span className="fw-bolder">Update</span>:{' '}
									{item.updatedAt ? dayjs(item.updatedAt).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>
					</tr>
				))}
		</ListingPage>
	);
};

export default PortalOwnersListing;
