import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification, successNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setBlogPosts = (payload) => {
	return {
		type: actionTypes.SET_BLOG_POSTS,
		payload,
	};
};

export const getBlogPosts = (apiKey, callback) => {
	return (dispatch) => {
		axios
			.get(`blog/posts?apiKey=${apiKey}`)
			.then(({ data }) => {
				dispatch(setBlogPosts(data.data));
				callback();
			})
			.catch((error) => {
				callback();
			});
	};
};

export const setBlogTags = (payload) => {
	return {
		type: actionTypes.SET_BLOG_TAGS,
		payload,
	};
};

export const getBlogTags = (apiKey, callback) => {
	return (dispatch) => {
		axios
			.get(`blog/tags?apiKey=${apiKey}`)
			.then(({ data }) => {
				dispatch(setBlogTags(data.data));
				callback();
			})
			.catch((error) => {
				callback();
			});
	};
};

export const setBlogAuthors = (payload) => {
	return {
		type: actionTypes.SET_BLOG_AUTHORS,
		payload,
	};
};

export const getBlogAuthors = (apiKey, callback) => {
	return (dispatch) => {
		axios
			.get(`blog/authors?apiKey=${apiKey}`)
			.then(({ data }) => {
				dispatch(setBlogAuthors(data.data));
				callback();
			})
			.catch((error) => {
				callback();
			});
	};
};

export const findAndReplaceWithPosts = (pageData, callback, callbackError) => {
	return (dispatch) => {
		axios
			.post('blog/posts/find-and-replace', pageData)
			.then(({ data }) => {
				dispatch(getBlogPosts(pageData.apiKey, pageData.pageType, () => {}));
				callback();
				dispatch(successNotification(data.message));
			})
			.catch((error) => {
				callbackError();
				dispatch(errorsNotification(error?.response?.data?.message));
			});
	};
};
