import axios from '../../config/axios';
import * as actionTypes from './actionTypes';
import { errorsNotification } from './notification';
// import { finishLoading, startLoading } from './loader';

export const setUsers = (payload) => {
	return {
		type: actionTypes.SET_USERS,
		payload,
	};
};

export const setHomeUsers = (payload) => {
	return {
		type: actionTypes.SET_HOME_USERS,
		payload,
	};
};

export const setUsersTotal = (payload) => {
	return {
		type: actionTypes.SET_USERS_TOTAL,
		payload,
	};
};

export const setUsersLastPage = (payload) => {
	return {
		type: actionTypes.SET_USERS_LAST_PAGE,
		payload,
	};
};

export const getUsers = (callback) => {
	return (dispatch) => {
		axios
			.get('user')
			.then(({ data }) => {
				dispatch(setUsers(data.data));
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
};

export const getUsersFilter = (queryParams, callback, isHome) => {
	return (dispatch) => {
		axios
			.post('user/filter', { queryParams })
			.then(({ data }) => {
				if (isHome) {
					dispatch(setHomeUsers(data.data));
				} else {
					dispatch(setUsers(data.data));
					dispatch(setUsersTotal(data.total));
					dispatch(setUsersLastPage(Math.ceil(data.total / queryParams.pageSize)));
				}
				callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				callback();
			});
	};
};

export const deleteUser = (id, queryParams, callback, errorCallback) => {
	return (dispatch) => {
		axios
			.delete(`user?id=${id}`)
			.then(({ data }) => {
				dispatch(getUsersFilter(queryParams, callback, false));
				// callback && callback();
			})
			.catch((error) => {
				dispatch(errorsNotification(error?.response?.data?.message));
				errorCallback && errorCallback();
			});
	};
}