import { FC } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/zh';

import enMessages from './messages/en.json';
import srMessages from './messages/sr.json';
// import deMessages from './messages/de.json';
// import esMessages from './messages/es.json';
// import frMessages from './messages/fr.json';
// import jaMessages from './messages/ja.json';
// import zhMessages from './messages/zh.json';

const allMessages = {
	en: enMessages,
	sr: srMessages,
};

const I18nProvider: FC = ({ children }: any) => {
	const locale = useLang();
	const messages = allMessages[locale];

	return (
		<IntlProvider locale={locale} messages={messages}>
			{children}
		</IntlProvider>
	);
};

export { I18nProvider };
