import React from 'react';

type Props = {
	color: any;
	endpoint: any;
	method: any;
	status: any;
};

const SingleLog: React.FC<Props> = ({ color, endpoint, method, status }) => {
	return (
		<div className="d-flex align-items-center mb-8">
			<span className={`bullet bullet-vertical h-45px bg-${color}`}></span>
			{/* begin::Description */}
			<div className="mx-4"></div>
			<div className="flex-grow-1">
				<div className="text-gray-800 fw-bolder fs-6">{endpoint}</div>
				<span className="text-muted fw-bold d-block">{method}</span>
			</div>
			{/* end::Description */}
			<span className={`badge badge-light-${color} fs-6 fw-bolder`}>{status}</span>
		</div>
	);
};

export default SingleLog;
