import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { getDomains, setListedDomains } from '../../../store/actions/domains';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const DomainsListing = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;
	const listedDomains = useSelector<RootState>((state) => state.domains.listedDomains) as any;

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getDomains(values.apiKey, () => setLoading(false)));
	};

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	useEffect(() => {
		setLoading(true);
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => { }));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && apiKeys.length && defaultApiKey) {
			setLoading(true);
			dispatch(getDomains(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setListedDomains(null));
			setLoading(false);
		}
	}, [apiKeys, defaultApiKey, dispatch]);

	const thead = [
		{
			width: 200,
			label: 'Domain',
		},
		{
			width: 200,
			label: 'Primary Pages',
		},
		{
			width: 150,
			label: 'Used',
		},
		{
			width: 200,
			label: 'Status',
		},
		{
			width: 150,
			label: 'Dates',
		},
	];

	return (
		<ListingPage
			listingName="Domains"
			listingSelector={listedDomains}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
		>
			{listedDomains?.length &&
				listedDomains.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<a
								href={`${item.isSslEnabled ? 'https:\\\\' : 'http:\\\\'}${item?.domain}`}
								rel="noreferrer noopener"
								target="_blank"
								className="text-info"
							>
								{item.isSslEnabled ? 'https:\\\\' : 'http:\\\\'}
								{item?.domain}
							</a>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								{item.primaryLandingPage && <div className='badge badge-light-success'>
									Primary Landing Page
								</div>}
								{item.primaryEmail && <div className='badge badge-light-success mt-1'>
									Primary Email
								</div>}
								{item.primaryBlogPost && <div className='badge badge-light-success mt-1'>
									Primary Blog Post
								</div>}
								{item.primarySitePage && <div className='badge badge-light-success mt-1'>
									Primary Site Page
								</div>}
								{item.primaryKnowledge && <div className='badge badge-light-success mt-1'>
									Primary Knowledge
								</div>}
								{item.primaryLegacyPage && <div className='badge badge-light-success mt-1'>
									Primary Legacy Page
								</div>}
							</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								{item.isUsedForBlogPost && <div className='badge badge-light-success'>
									Blog Posts
								</div>}
								{item.isUsedForSitePage && <div className='badge badge-light-success mt-1'>
									Site Pages
								</div>}
								{item.isUsedForLandingPage && <div className='badge badge-light-success mt-1'>
									Landing Pages
								</div>}
								{item.isUsedForEmail && <div className='badge badge-light-success mt-1'>
									Emails
								</div>}
								{item.isUsedForKnowledge && <div className='badge badge-light-success mt-1'>
									Knowledge
								</div>}
							</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div
									className={`badge ${!item.isPendingOwnershipValidation ? 'badge-light-success' : 'badge-light-danger'
										}`}
								>
									{!item.isPendingOwnershipValidation ? 'Ownership Validated' : 'Pending Ownership Validation'}
								</div>
								<div className={`badge mt-1 ${item.isResolving ? 'badge-light-success' : 'badge-light-danger'}`}>
									{item.isResolving ? 'Resolving' : 'Not Resolving'}
								</div>
								<div className={`badge mt-1 ${item.isSslEnabled ? 'badge-light-success' : 'badge-light-danger'}`}>
									{item.isSslEnabled ? 'SSL Enabled' : 'SSL Disabled'}
								</div>
								<div className={`badge mt-1 ${item.isDnsCorrect ? 'badge-light-success' : 'badge-light-danger'}`}>
									{item.isDnsCorrect ? 'DNS Correct' : 'DNS Incorrect'}
								</div>
							</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Added</span>:{' '}
									{item.created ? dayjs(item.created).format('DD. MMM YYYY') : 'N/A'}
								</div>
								<div className="badge mt-2 badge-light fw-normal">
									<span className="fw-bolder">Update</span>:{' '}
									{item.updated ? dayjs(item.updated).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>

						{/* <td className='text-end'>
							<div
								className="btn btn-icon btn-light-info btn-active-info btn-sm"
								title="Edit"
							>
								<KTSVG
									path="/media/icons/duotune/art/art005.svg"
									className="svg-icon-danger svg-icon-4"
								/>
							</div>
							<div
								className="btn btn-icon btn-light-danger btn-active-danger btn-sm ms-2"
								title="Delete"
							>
								<KTSVG
									path="/media/icons/duotune/general/gen027.svg"
									className="svg-icon-danger svg-icon-4"
								/>
							</div>
						</td> */}
					</tr>
				))}
		</ListingPage>
	);
};

export default DomainsListing;
