import React from 'react';
import { BlogFindAndReplace } from './components/BlogFindAndReplace';
import { PageTitle } from '../../../_metronic/layout/core';

const BlogsFindAndReplaceWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Blog Posts Find and Replace</PageTitle>
			<BlogFindAndReplace />
		</>
	);
};

export default BlogsFindAndReplaceWrapper;
