import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import ListingPage from '../../modules/listing-page/ListingPage';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getApiKeys } from '../../../store/actions/apiKeys';
import { getTemplates, setListedTemplates } from '../../../store/actions/templates';

const templateCategory: any = {
	0: {
		text: 'Unmapped',
		color: 'badge-light-danger',
	},
	1: {
		text: 'Landing Pages',
		color: 'badge-light-primary',
	},
	2: {
		text: 'Email',
		color: 'badge-light-warning',
	},
	3: {
		text: 'Blog Post',
		color: 'badge-light-info',
	},
	4: {
		text: 'Site Page',
		color: 'badge-light-primary',
	},
};

const listingSchema = Yup.object().shape({
	apiKey: Yup.string().required('API key is required').min(1, 'API key is required'),
});

const initialValues = {
	apiKey: '',
};

const TemplatesListing = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;
	const listedTemplates = useSelector<RootState>((state) => state.templates.listedTemplates) as any;

	const onSubmit = (values: any) => {
		setLoading(true);
		dispatch(getTemplates(values.apiKey, () => setLoading(false)));
	};

	const defaultApiKey = apiKeys.find((key: { default: string }) => key.default)?.apiKey;
	initialValues.apiKey = defaultApiKey || '';

	useEffect(() => {
		setLoading(true);
		if (!apiKeys.length) {
			dispatch(getApiKeys(() => {}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (apiKeys && defaultApiKey) {
			dispatch(getTemplates(defaultApiKey, () => setLoading(false)));
		} else {
			dispatch(setListedTemplates(null));
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiKeys, defaultApiKey, dispatch]);

	const thead = [
		{
			width: 125,
			label: 'File Name',
		},
		{
			width: 125,
			label: 'Folder',
		},
		{
			width: 125,
			label: 'Category',
		},
		{
			width: 125,
			label: 'Dates',
		},
	];

	return (
		<ListingPage
			listingName="Templates"
			listingSelector={listedTemplates}
			listingSchema={listingSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			loading={loading}
			thead={thead}
		>
			{listedTemplates?.length &&
				listedTemplates.map((item: any, index: any) => (
					<tr key={index}>
						<td>
							<div>{item.filename}</div>
						</td>

						<td>
							<div>{item.folder}</div>
						</td>
						<td>
							<div
								className={`badge ${
									templateCategory[item?.category_id]
										? templateCategory[item?.category_id].color
										: 'badge-light-warning'
								}`}
							>
								{templateCategory[item?.category_id] ? templateCategory[item?.category_id].text : 'N/A'}
							</div>
						</td>
						<td>
							<div className="d-inline-flex flex-column">
								<div className="badge badge-light fw-normal">
									<span className="fw-bolder">Added</span>:{' '}
									{item.created ? dayjs(item.created).format('DD. MMM YYYY') : 'N/A'}
								</div>
								<div className="badge mt-2 badge-light fw-normal">
									<span className="fw-bolder">Update</span>:{' '}
									{item.updated ? dayjs(item.updated).format('DD. MMM YYYY') : 'N/A'}
								</div>
							</div>
						</td>
					</tr>
				))}
		</ListingPage>
	);
};

export default TemplatesListing;
