/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import ContentLoader from '../../../_metronic/layout/components/ContentLoader';
import { PageTitle } from '../../../_metronic/layout/core';

// interface IPageListingProps {
// 	listingName: any;
// 	listingSelector: any;
// 	listingSchema: any;
// 	initialValues: any;
// 	onSubmit: any;
// 	loading: any;
// 	thead: any;
// 	children: ReactNode;
// }

const PagesListingWrapper = ({
	listingName,
	listingSelector,
	listingSchema,
	initialValues,
	onSubmit,
	loading,
	thead,
	children,
	formAdditional,
	findAndReplaceLink,
	addNewLink,
	hasCheckbox = false,
	selectedArea = [],
	setSelectedArea,
	hideTable = false,
	hideTotal = false,
	selectionHtml,
}: any) => {
	const apiKeys = useSelector<RootState>((state) => state.apiKeys.apiKeys) as any;

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: listingSchema,
		onSubmit: onSubmit,
	});

	const pageTitle = listingName + ' Listing';

	return (
		<>
			<PageTitle breadcrumbs={[]}>{pageTitle}</PageTitle>
			<div className={`${listingName}-g`}>
				<div className="card-header px-7 py-6">
					<form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
						<div>
							<label className="form-label fs-6 fw-bolder text-dark">Hubspot API Key</label>
							<select
								className="form-select form-select-solid"
								{...formik.getFieldProps('apiKey')}
								disabled={apiKeys.length === 0}
							>
								<option disabled selected>
									Select key...
								</option>
								{apiKeys.map((key: any, index: any) => (
									<option key={index} value={key.apiKey}>
										{key.name}
									</option>
								))}
							</select>
							{formik.touched.apiKey && formik.errors.apiKey && (
								<div className="fv-plugins-message-container">
									<span role="alert">{formik.errors.apiKey}</span>
								</div>
							)}

							{apiKeys.length === 0 ? (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">No API keys added to account, to continue</label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Add new API Key
									</Link>
								</div>
							) : (
								<div className="fv-row mt-3">
									<label className="form-label fs-6 text-muted">Want to add new API key? </label>
									<Link to="/api-keys" className="text-primary fs-6">
										{' '}
										Click here
									</Link>
								</div>
							)}
						</div>

						<div className="separator mx-1 my-8"></div>

						{formAdditional && formAdditional(formik)}

						<div className="text-center">
							<button
								type="submit"
								id="kt_sign_in_submit"
								className="btn btn-md w-40 mb-3 btn-primary"
								disabled={!formik.isValid || apiKeys.length === 0}
							>
								Submit
							</button>
						</div>
					</form>
				</div>

				{!hideTable ? (
					<div className={`card listedDomains mt-5`}>
						{/* begin::Header */}
						<div className="card-header">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label fw-bolder fs-3 mb-1">Listed {listingName}</span>
								{!loading && listingSelector && listingSelector.length !== null && !hideTotal ? (
									<span className="text-muted mt-1 fw-bold fs-7">Total: {listingSelector.length} results</span>
								) : null}
							</h3>

							<div className="card-title align-items-center flex-row">
								{findAndReplaceLink && (
									<Link to={findAndReplaceLink} className="btn btn-sm btn-primary mx-2">
										Find and replace
									</Link>
								)}
								{addNewLink && (
									<Link to={addNewLink} className="btn btn-sm btn-primary">
										Add New
									</Link>
								)}
							</div>
						</div>
						{/* end::Header */}
						{/* begin::Body */}
						<div className="card-body p-0">
							{/* begin::Table container */}
							<div className="table-responsive">
								{selectionHtml && (
									<div className={selectedArea.length > 0 ? 'd-block' : 'd-none'}>
										{selectionHtml(formik.values.apiKey, formik.values.pageType)}
									</div>
								)}
								{/* begin::Table */}
								{loading && (
									<div style={{ minHeight: 150, position: 'relative', width: '100%' }}>
										<ContentLoader />
									</div>
								)}
								{!loading && listingSelector?.length ? (
									<table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
										{/* begin::Table head */}
										<thead>
											<tr className="border-gray-200 fs-5 fw-bold bg-lighten">
												{/* <th className={`w-50px`}></th> */}
												{hasCheckbox && (
													<th className="fs-7">
														<div
															className="form-check form-check-solid fv-row"
															style={{ marginRight: 20, marginBottom: 0, width: 21, height: 21 }}
														>
															<input
																type="checkbox"
																style={{ width: 21, height: 21, marginBottom: 0 }}
																className="form-check-input"
																onChange={() =>
																	setSelectedArea((p: any) => (p === listingSelector ? [] : listingSelector))
																}
																checked={selectedArea?.length === listingSelector?.length}
															/>
														</div>
													</th>
												)}
												{thead.map((value: any, index: number) => (
													<th
														key={index + '-value__label'}
														className={`min-w-${value.width}px ${value.className ? value.className : ''}`}
													>
														{value.label}
													</th>
												))}
											</tr>
										</thead>
										{/* end::Table head */}
										{/* begin::Table body */}
										<tbody className="fw-6 fw-bold text-gray-600">{listingSelector.length > 0 ? children : ''}</tbody>
										{/* end::Table body */}
									</table>
								) : null}
								{/* end::Table */}
							</div>
							{/* end::Table container */}
						</div>
						{/* begin::Body */}
					</div>
				) : (
					<>
						{loading && (
							<div style={{ minHeight: 150, position: 'relative', width: '100%' }}>
								<ContentLoader />
							</div>
						)}
						{!loading && children}
					</>
				)}
			</div>
		</>
	);
};

export default PagesListingWrapper;
