/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Files from './components/Files';
import { PageTitle } from '../../../_metronic/layout/core';

const FilesWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Files</PageTitle>
			<Files />
		</>
	);
};

export default FilesWrapper;
