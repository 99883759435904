/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogsFilter } from '../../../../store/actions/logs';
import { RootState } from '../../../../store/reducers';
import ContentLoader from '../../../../_metronic/layout/components/ContentLoader';
import SingleLog from './SingleLog';

type Props = {
	className: string;
};

const DashboardActivity: React.FC<Props> = ({ className }) => {
	const dispatch = useDispatch();
	const homeLogs = useSelector<RootState>((state) => state.logs.homeLogs) as any;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		dispatch(
			getLogsFilter(
				{ pageSize: 4, pageNumber: 1, sortField: 'createdAt', sortOrder: 'desc' },
				() => setLoading(false),
				true
			)
		);
		// eslint-disable-next-line
	}, []);

	const colorStatus = (status: any) => {
		const statusFirstNum = Math.floor(status / 100);
		if (statusFirstNum === 2) {
			return 'success';
		} else if (statusFirstNum === 5) {
			return 'danger';
		} else if (statusFirstNum === 4) {
			return 'warning';
		} else if (statusFirstNum === 3)  {
			return 'info';
		} else {
			return 'warning';
		}
	};

	return (
		<div className={`card ${className}`} style={{ minHeight: 300 }}>
			{/* begin::Header */}
			<div className="card-header border-0">
				<h3 className="card-title fw-bolder text-dark">Recent activity</h3>
			</div>
			{/* end::Header */}
			{/* begin::Body */}
			<div className="card-body pt-2">
				{loading && <ContentLoader />}
				{homeLogs.length > 0 &&
					!loading &&
					homeLogs.map((log: any, index: any) => (
						<SingleLog
							key={index}
							color={colorStatus(log.status)}
							endpoint={log.endpoint}
							method={log.method}
							status={log.status}
						/>
					))}
			</div>
			{/* end::Body */}
		</div>
	);
};

export { DashboardActivity };
