import * as actionTypes from '../actions/actionTypes';

const initialState = {
	users: [],
	homeUsers: [],
	usersTotal: 0,
	usersLastPage: 1
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case actionTypes.SET_HOME_USERS:
			return {
				...state,
				homeUsers: action.payload,
			};
		case actionTypes.SET_USERS_TOTAL:
			return {
				...state,
				usersTotal: action.payload,
			};
		case actionTypes.SET_USERS_LAST_PAGE:
			return {
				...state,
				usersLastPage: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
