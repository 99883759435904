import clsx from 'clsx';
import React, { FC } from 'react';
import { toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
// import { useLayout } from '../../core';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3';
const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
	// const { config } = useLayout();

	return (
		<div className="d-flex align-items-stretch flex-shrink-0">
			{/* NOTIFICATIONS */}
			{/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
				<div
					className={clsx('btn btn-icon btn-active-light-primary position-relative', toolbarButtonHeightClass)}
					data-kt-menu-trigger="click"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					data-kt-menu-flip="bottom"
				>
					<KTSVG path="/media/icons/duotune/general/gen007.svg" className={toolbarButtonIconSizeClass} />
				</div>
				<HeaderNotificationsMenu />
			</div> */}

			{/* begin::User */}
			<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)} id="kt_header_user_menu_toggle">
				{/* begin::Toggle */}
				<div
					className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
					data-kt-menu-trigger="click"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					data-kt-menu-flip="bottom"
				>
					<img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="metronic" />
				</div>
				<HeaderUserMenu />
				{/* end::Toggle */}
			</div>
			{/* end::User */}
		</div>
	);
};

export { Topbar };
