import * as actionTypes from '../actions/actionTypes';

const initialState = {
	blogPosts: null,
	blogTags: null,
	blogAuthors: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_BLOG_POSTS:
			return {
				...state,
				blogPosts: action.payload,
			};
		case actionTypes.SET_BLOG_TAGS:
			return {
				...state,
				blogTags: action.payload,
			};
		case actionTypes.SET_BLOG_AUTHORS:
			return {
				...state,
				blogAuthors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
